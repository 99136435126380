import React from "react";
import { decodeAbschaltungen } from "../01_Helpers/anlagenAbschaltungHelper";
import { BsPower } from "react-icons/bs";
import { decodeInfoByte } from "../01_Helpers/anlagenStatusHelper";

const AnlagenAbschaltungenWidget = (props) => {
  let abschaltungen = props.abschaltungen;
  let status = props.status;
  let infobyte = decodeInfoByte(status);
  let decodedAbschaltungen = decodeAbschaltungen(abschaltungen);
  if (infobyte.includes("Schaltzeiten gesperrt")) {
    decodedAbschaltungen.push("Schaltzeiten");
  }
  if (decodedAbschaltungen.length === 0) props.setHideAbschaltungen(true);
  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Abschaltungen</h3>
      </div>
      <div className='anlagenWidgetBody'>
        {decodedAbschaltungen.map((item, index, array) => {
          return (
            <div className='abschaltungenEntry marginTop05' key={index}>
              <span className='abschaltungVal'>
                <BsPower className='orange-800' />
              </span>
              <span className='abschaltungIndex gray-800 bold'>{item}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnlagenAbschaltungenWidget;
