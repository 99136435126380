exports.decodeEingaenge = (data) => {
  let eingangBuffer = new Buffer.from(data, "hex");
  let eingaenge = [];
  const getEingangStatus = (dataByte) => {
    if (dataByte === 0x1) return "aktiv";
    if (dataByte === 0x2) return "offen";
    if (dataByte === 0x4) return "Kurzschluss";
  };
  for (let i = 0; i < eingangBuffer.length; i++) {
    if (eingangBuffer[i] === 0) continue;
    eingaenge.push({
      eingangNummer: i + 1,
      eingangStatus: getEingangStatus(eingangBuffer[i]),
    });
  }
  return eingaenge;
};
