import React, { useEffect, useState } from "react";
import "./Main.css";
import RemoteWidget from "../DashboardWidgets/RemoteWidget";
import { refreshLocalStorage } from "../01_Helpers/refreshLocalStorage";

function RemoteDashboard(props) {
  let { remotes, setRemotes } = props;
  let [remotesWithError, setRemotesWithErrors] = useState([]);
  let [orderRemotes, setOrderRemotes] = useState(false);
  const hasError = (remID, errorState) => {
    let errorRemotes = remotesWithError;
    if (!errorState && !remotesWithError.includes(remID))
      setRemotesWithErrors([...errorRemotes, remID]);
    if (errorState && remotesWithError.includes(remID))
      setRemotesWithErrors(errorRemotes.filter((id) => id !== remID));
  };

  useEffect(() => {
    refreshLocalStorage().then(() => {
      let scope = JSON.parse(localStorage.getItem("scope"));
      setRemotes(scope.remote);
      setOrderRemotes(!orderRemotes);
    });
  }, []);

  useEffect(() => {
    if (remotesWithError.length === 0) return;
    let remoteList = remotes;
    let nonErrorRemotes = remoteList.filter(
      (remote) => !remotesWithError.includes(remote.remoteID)
    );
    let errorRemotes = remoteList.filter((remote) =>
      remotesWithError.includes(remote.remoteID)
    );
    let reorderedRemoteList = [...errorRemotes, ...nonErrorRemotes];
    setRemotes(reorderedRemoteList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remotesWithError, orderRemotes]);

  let token = JSON.parse(localStorage.getItem("token"));

  return (
    <div className='frame displayBlock padding10'>
      <h3>Dashboard</h3>
      <div className='RemoteDashboard padding10 gap10'>
        {remotes &&
          remotes.map((item) => (
            <RemoteWidget
              key={item.remoteID}
              remoteID={item.remoteID}
              href={item.href}
              token={token}
              hasError={hasError}
            />
          ))}
      </div>
    </div>
  );
}

export default RemoteDashboard;
