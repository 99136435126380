import React from "react";
import "./RemoteWidget.css";

function TempRemoteWidget(props) {
  return (
    <div className='SubWidget card gridItemsCenter delay3 padding00'>
      <h4>Temperatur</h4>
      {props.remoteOk ? (
        <div className='content'>
          <div className='tempInternal'>
            {props.temperatur?.tempInternal != null
              ? `${props.temperatur.tempInternal}°C`
              : ""}
          </div>
          <div className='tempExternal'>
            {props.temperatur?.tempExternal != null
              ? `${props.temperatur.tempExternal}°C`
              : ""}
          </div>
        </div>
      ) : (
        <div className='notAvailable'>n.A.</div>
      )}
    </div>
  );
}

export default TempRemoteWidget;
