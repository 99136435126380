import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import DisplayTable from "../02_Tools/DisplayTable";
import AdressbookPanel from "./AdressbookPanel";
import InputNotificationPanel from "./InputNotificationPanel";
import SaaNotificationPanel from "./SaaNotificationPanel";
import TempNotificationPanel from "./TempNotificationPanel";
import { BiMailSend } from "react-icons/bi";
import { v4 as uuid } from "uuid";
import { ScaleLoader } from "react-spinners";

let generateTableData = (data) => {
  if (!Array.isArray(data)) return;
  let tableHead = ["Typ", "Eingang", "Details", "E-Mails"];
  let tableBody = data.map((item) => {
    let getEventType = (typ) => {
      if (typ === "saa") return "SAA";
      if (typ === "temp") return "Temperatur";
      if (typ === "input") return "Eingang";
    };
    let getEventDetails = (data) => {
      if (item.eventType === "saa") {
        return item.saaState;
      }
      if (item.eventType === "temp") {
        if (item.tempUnder != null) return `< ${item.tempUnder} °C`;
        if (item.tempOver != null) return `> ${item.tempOver} °C`;
      }
      if (item.eventType === "input") {
        return item.inputState ? "Aktiv" : "Inaktiv";
      }
    };
    return {
      Typ: getEventType(item.eventType),
      Eingang: item.eventInput,
      Details: getEventDetails(item),
      "E-Mails": JSON.parse(item.mailAdresses),
      id: item.eventID,
    };
  });
  let classNames = "padding10";
  return {
    tableHead,
    tableBody,
    classNames,
  };
};

const NotificationEvents = (props) => {
  //Globals
  const { remoteID } = useParams();
  let scope = JSON.parse(localStorage.getItem("scope"));
  let href = scope.remote.find((item) => item.remoteID === remoteID).href;

  // Events
  const [events, setEvents] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  // Table Generation
  let tableHead = useRef();
  let tableBody = useRef();
  let classNames = useRef();

  //Table States
  const [displayTableHead, setDisplayTableHead] = useState();
  const [displayTableBody, setDisplayTableBody] = useState();
  const [firstTableCol, setFirstTableCol] = useState();
  const [searchWord, setSearchWord] = useState("");
  let [deleteOption, setDeleteOption] = useState(null);
  let [editOption, setEditOption] = useState(null);

  //Event States
  const [eventID, setEventID] = useState(uuid());
  const [eventType, setEventType] = useState(""); // input, temp, saa
  const [eventInput, setEventInput] = useState(null); // [intern, extern], [0...7], null
  const [inputState, setInputState] = useState(null); // 1 0 null
  const [tempOver, setTempOver] = useState(null); // float, null
  const [tempUnder, setTempUnder] = useState(null); // float, null
  const [saaState, setSaaState] = useState(null); // error, alarm, null
  const [clearNotification, setClearNotification] = useState(false); //true, false
  const [adresses, setAdresses] = useState([]);
  let [anlagenID, setAnlagenID] = useState(null);
  let [waitForEventAdded, setWaitForEventAdded] = useState(false);
  //Event Options
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    if (editOption == null) return;
    let editEvent = events.find((item) => item.eventID === editOption);
    setEventID(editOption);
    setEventType(editEvent?.eventType);
    setAdresses(JSON.parse(editEvent.mailAdresses));
    setClearNotification(editEvent.clearNotification);
    if (editEvent.eventType === "input") {
      setEventInput(editEvent.eventInput);
      setInputState(editEvent.inputState);
    }
    if (editEvent.eventType === "saa") {
      setEventInput(editEvent.eventInput);
      setSaaState(editEvent.saaState);
    }
    if (editEvent.eventType === "temp") {
      setEventInput(editEvent.eventInput);
      if (editEvent.tempOver !== null) {
        setTempOver(editEvent.tempOver);
      }
      if (editEvent.tempUnder !== null) {
        setTempOver(editEvent.tempUnder);
      }
    }
  }, [editOption, events]);

  useEffect(() => {
    if (deleteOption == null) return;
    fetch(`${href}/notificationEvents/${deleteOption}`, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.getItem("token"),
        "accept-encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res.notificationEvents))
          return setEvents(res.notificationEvents);
        return setEvents([res.notificationEvents]);
      })
      .catch((err) => console.error);
  }, [deleteOption, href]);

  useEffect(() => {
    if (displayTableHead == null) return;
    setSearchWord("");
    let newTableHead = tableHead.current.reduce((prev, item, index, array) => {
      if (index === 0)
        return [...prev, array.find((newFirst) => newFirst === firstTableCol)];
      return item === firstTableCol ? [...prev, array[0]] : [...prev, item];
    }, []);
    setDisplayTableHead(newTableHead);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTableCol]);

  useEffect(() => {
    if (displayTableBody == null) return;
    if (searchWord === "" || searchWord == null)
      return setDisplayTableBody(tableBody.current);
    let newBody = [];

    tableBody.current.forEach((item) => {
      let newItem = { ...item }; // Kopie von 'item' erzeugen, weil sonst tablBody verändert wird (Danke call by reference)
      if (item[firstTableCol] == null) return;
      if (Array.isArray(item[firstTableCol])) {
        if (item[firstTableCol].length === 0) return;
        newItem[firstTableCol] = item[firstTableCol].filter((searchItem) =>
          searchItem.toLowerCase().startsWith(searchWord.toLowerCase())
        );
        if (newItem[firstTableCol] == null) return;
        if (newItem[firstTableCol].length === 0) return;
        return newBody.push(newItem);
      }
      if (typeof item[firstTableCol] != "string") return;
      if (
        item[firstTableCol].toLowerCase().startsWith(searchWord.toLowerCase())
      )
        return newBody.push(item);
    });

    setDisplayTableBody(newBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord]);

  useEffect(() => {
    fetch(`${href}?timeLimit=latest`, {
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Authorization: JSON.parse(localStorage.getItem("token")),
      },
    })
      .then((res) => {
        if (res.status !== 200) throw new Error("Error");
        return res.json();
      })
      .then((res) => {
        setAnlagenID(res.scope?.anlagen[0]?.anlagenID);
        setInputs(res.remoteInputConfig);
        setEvents(
          Array.isArray(res.notificationEvents)
            ? res.notificationEvents
            : [res.notificationEvents]
        );
      });
  }, [href]);

  useEffect(() => {
    if (!Array.isArray(events)) return;
    let tData = generateTableData(events);
    tableHead.current = tData.tableHead;
    tableBody.current = tData.tableBody;
    classNames.current = tData.classNames;
    setDisplayTableHead(tableHead.current);
    setDisplayTableBody(tableBody.current);
    setFirstTableCol(tableHead.current[0]);
    setIsLoaded(true);
  }, [events]);

  if (!isLoaded) return <></>;

  return (
    <div className='frame padding10 maxWidth700px marginTop10'>
      <h3>Benachrichtigungen</h3>

      <div className='flexRow flexFlexEnd gap10 padding10'>
        <label className='marginToBoAuto'>Gruppierung:</label>
        <select
          className='maxWidth20ch'
          value={firstTableCol}
          onChange={(e) => setFirstTableCol(e.target.value)}
        >
          {displayTableHead.map((item, index) => (
            <option value={item} key={index}>{item}</option>
          ))}
        </select>
        <label className='marginToBoAuto'>Suche:</label>
        <input
          onChange={(e) => setSearchWord(e.target.value)}
          value={searchWord}
          className='maxWidth20ch'
        ></input>
      </div>
      <div className='contentBox card padding 10'>
        <DisplayTable
          head={displayTableHead}
          body={displayTableBody}
          classNames='padding10 contentBox'
          setEditOption={setEditOption}
          setDeleteOption={setDeleteOption}
          activeOption={editOption}
        />
      </div>
      <span className='marginTop10'>
        <hr orientation='horizontal'></hr>
      </span>
      {waitForEventAdded ? (
        <div className='displayBlock maxWidth700px marginTop10 padding10 card gridItemsCenter'>
          <ScaleLoader color='orange' />
        </div>
      ) : (
        <form className='displayBlock gridCol2x1fr maxWidth700px marginTop10 padding10 textAlignStart card'>
          <h5 className='marginTop10'>
            {Array.isArray(events) &&
            events?.findIndex((item) => item?.eventID === eventID) !== -1
              ? "Benachrichtigung bearbeiten:"
              : "Benachrichtigung hinzufügen:"}
          </h5>
          <select
            value={eventType}
            onChange={(e) => {
              let settedOption = e.target.value;
              setEventType(settedOption);
            }}
            className='marginTop05'
          >
            <option value='' disabled>
              Bitte wählen...
            </option>
            <option value='input'>Eingang</option>
            <option value='temp'>Temperatur</option>
            {anlagenID != null && anlagenID !== 0 && (
              <option value='saa'>Sprachalamierungsanlage</option>
            )}
          </select>
          {eventType !== "" && (
            <>
              <div className='gridCol08ch1fr gap10 placeContentStart'>
                {eventType === "input" && (
                  <InputNotificationPanel
                    inputs={inputs}
                    setEventInput={setEventInput}
                    setInputState={setInputState}
                    inputState={inputState}
                    eventInput={eventInput}
                  />
                )}
                {eventType === "temp" && (
                  <TempNotificationPanel
                    tempOver={tempOver}
                    tempUnder={tempUnder}
                    setTempOver={setTempOver}
                    setTempUnder={setTempUnder}
                    eventInput={eventInput}
                    setEventInput={setEventInput}
                  />
                )}
                {eventType === "saa" && (
                  <SaaNotificationPanel
                    saaState={saaState}
                    setSaaState={setSaaState}
                  />
                )}
                <span className='span2'>
                  <span
                    className={
                      clearNotification
                        ? "flexRow gap05 orange-800 resizeOnHover11 pointer"
                        : "flexRow gap05 gray-400 resizeOnHover11 pointer textDecLineThrough"
                    }
                    onClick={(e) => {
                      setClearNotification(!clearNotification);
                    }}
                  >
                    <BiMailSend className='fontSize15'></BiMailSend>
                    <p className='marginToBoAuto'>"Event gelöscht"-Nachricht</p>
                  </span>
                </span>
              </div>
              <div>
                <AdressbookPanel
                  adresses={adresses}
                  setAdresses={setAdresses}
                />
              </div>

              <span className='marginTop05 span2'>
                <hr orientation='horizontal'></hr>
              </span>

              <div>
                {Array.isArray(events) &&
                events.findIndex((item) => item.eventID === eventID) !== -1 ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setEventID(uuid());
                    }}
                  >
                    Neu...
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <button
                disabled={eventType == null ? true : false}
                onClick={(e) => {
                  e.preventDefault();
                  setWaitForEventAdded(true);
                  let newNotificiationEvent = {
                    eventID: eventID,
                    clearNotification: clearNotification,
                    eventType: eventType,
                  };
                  if (eventType === "input") {
                    if (
                      ![0, 1, 2, 3, 4, 5, 6, 7].includes(parseInt(eventInput))
                    )
                      return alert("Bitte wählen Sie einen Eingang!");
                    if (inputState === null)
                      return alert("Bitte wählen Sie einen Status!");
                    newNotificiationEvent.eventInput = eventInput;
                    newNotificiationEvent.inputState =
                      inputState === "true" || inputState ? true : false;
                  }
                  if (eventType === "temp") {
                    if (
                      (tempOver === null && tempUnder === null) ||
                      eventInput === null
                    )
                      return alert(
                        "Bitte überprüfen Sie die Temperatureinstellungen auf Vollständigkeit!"
                      );
                    newNotificiationEvent.eventInput = eventInput;
                    if (tempOver != null) {
                      newNotificiationEvent.tempOver = parseFloat(tempOver);
                    }
                    if (tempUnder != null) {
                      newNotificiationEvent.tempUnder = parseFloat(tempUnder);
                    }
                  }
                  if (eventType === "saa") {
                    if (saaState === null)
                      return alert("Bitte wählen Sie eine Anlagen Status!");
                    newNotificiationEvent.saaState = saaState;
                    newNotificiationEvent.anlagenID = anlagenID;
                  }
                  if (adresses.length === 0)
                    return alert(
                      "Bitte fügen Sie mindestens eine E-Mail Adresse der Empfängerliste hinzu."
                    );
                  newNotificiationEvent.mailAdresses = JSON.stringify(adresses);
                  let oldEvents = events.map((item) => {
                    if (Object.keys(item).includes(remoteID))
                      delete item.remoteID;
                    let returnItem = Object.keys(item)
                      .filter((k) => item[k] != null)
                      .filter((k) => k !== "remoteID")
                      .reduce((a, k) => ({ ...a, [k]: item[k] }), {});
                    return returnItem;
                  });
                  let notificationEvents = {};
                  if (
                    oldEvents.findIndex((item) => item.eventID === eventID) ===
                    -1
                  )
                    notificationEvents = [...oldEvents, newNotificiationEvent];
                  else {
                    let indexOfNotification = oldEvents.findIndex(
                      (item) => item.eventID === eventID
                    );
                    notificationEvents = [...oldEvents];
                    notificationEvents[indexOfNotification] =
                      newNotificiationEvent;
                  }
                  fetch(href, {
                    method: "PATCH",
                    headers: {
                      Authorization: localStorage.getItem("token"),
                      "Accept-Encoding": "gzip",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      notificationEvents: notificationEvents,
                    }),
                  })
                    .then((res) => {
                      if (res.status === 200) return res.json();
                      else throw new Error("Errör");
                    })
                    .then((res) => {
                      if (Array.isArray(res.notificationEvents))
                        return setEvents(res.notificationEvents);
                      return setEvents([res.notificationEvents]);
                    })
                    .then((res) => {
                      setEventID(uuid());
                      setEventType("");
                      setEventInput(null);
                      setInputState(null);
                      setTempOver(null);
                      setTempUnder(null);
                      setSaaState(null);
                      setClearNotification(null);
                      setWaitForEventAdded(false);
                    });
                }}
              >
                {Array.isArray(events) &&
                events.findIndex((item) => item.eventID === eventID) !== -1
                  ? "Ändern"
                  : "Hinzufügen"}
              </button>
            </>
          )}
        </form>
      )}
    </div>
  );
};

export default NotificationEvents;

/*

*/
