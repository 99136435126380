import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OutputSettingsWidget from "./OutputWidget";
import "./OutputSetting.css";
import AddOutputAction from "./AnlagenAddOutputAction";

const RemoteOutputSettings = (props) => {
  let { remoteID } = useParams();
  let scope = JSON.parse(localStorage.getItem("scope"));
  let token = JSON.parse(localStorage.getItem("token"));
  const [editMode, setEditMode] = useState(false);
  const [addOutputAction, setAddOutputAction] = useState(null);
  const [remoteOutputConfig, setRemoteOutputConfig] = useState([]);
  const [remoteInputConfig, setRemoteInputConfig] = useState([]);
  const [oldActions, setOldActions] = useState([]);
  const [outputIndex, setOutputIndex] = useState(0);
  let [prevHead, setPrevHead] = useState("");
  let [prevDesc, setPrevDesc] = useState("");
  let [outputActions, setOutputActions] = useState([]);
  const [outputStatus, setoutputStatus] = useState(0);
  let dummyOutputConfig = [0, 1].map((item) => {
    return {
      remoteID: remoteID,
      remoteOutputIndex: item,
      remoteOutputName: `Ausgang ${item + 1}`,
      remoteOutputDesc: `Beschreibung Ausgang ${item + 1}`,
      remoteOutputAction: [],
    };
  });
  useEffect(() => {
    let remoteScope = scope.remote.find((item) => item.remoteID === remoteID);

    let outputConfig = async () => {
      return await fetch(`${remoteScope.href}`, {
        headers: {
          Authorization: token,
        },
        method: "GET",
      }).then((res) => {
        return res.json();
      });
    };

    outputConfig().then((res) => {
      setRemoteOutputConfig(
        res.remoteOutputConfig.length === 0
          ? dummyOutputConfig
          : res.remoteOutputConfig
      );
      setRemoteInputConfig(res.remoteInputConfig);
      setoutputStatus(res.statusRemote.outputs);
      console.log(res.statusRemote.outputsps);
    });
  }, []);
  if (addOutputAction == null)
    return (
      <div className='remoteOutputSettingsFrame'>
        {remoteOutputConfig.map((item, index) => (
          <OutputSettingsWidget
            key={index}
            outputConfig={item}
            outputStatus={outputStatus}
            setOutputConfig={setRemoteOutputConfig}
            setAddOutputAction={setAddOutputAction}
            editMode={editMode}
            setEditMode={setEditMode}
            prevHead={prevHead}
            setPrevHead={setPrevHead}
            prevDesc={prevDesc}
            setPrevDesc={setPrevDesc}
            outputActions={outputActions}
            setOutputActions={setOutputActions}
            inputConfig={remoteInputConfig}
            oldActions={oldActions}
            setOldActions={setOldActions}
            setOutputIndex={setOutputIndex}
          />
        ))}
      </div>
    );
  else
    return (
      <div className='remoteOutputSettingsFrame'>
        {remoteOutputConfig
          .map((item, index) => (
            <OutputSettingsWidget
              key={index}
              outputConfig={item}
              outputStatus={outputStatus}
              setOutputConfig={setRemoteOutputConfig}
              setAddOutputAction={setAddOutputAction}
              editMode={editMode}
              setEditMode={setEditMode}
              outputActions={outputActions}
              setOutputActions={setOutputActions}
              inputConfig={remoteInputConfig}
              oldActions={oldActions}
              setOldActions={setOldActions}
              setOutputIndex={setOutputIndex}
            />
          ))
          .filter((item, index) => index === addOutputAction)}
        <AddOutputAction
          inputConfig={remoteInputConfig}
          setAddOutputAction={setAddOutputAction}
          setOutputActions={setOutputActions}
          outputActions={outputActions}
          outputIndex={outputIndex}
        />
      </div>
    );
};

export default RemoteOutputSettings;
