import React, { useEffect, useState } from "react";
import { baseurl } from "../01_Helpers/endpoints";
import { useForm } from "react-hook-form";
import { adminPatchAllDB } from "../01_Helpers/fetchStuff";

const RemoteUpdate = (props) => {
  const { register, handleSubmit } = useForm();
  let [allRemoteSelected, setAllRemotesSelected] = useState(false);
  let [remotesForUpdate, setRemoteForUpdate] = useState([]);
  let [newSWVersion, setNewSWVersion] = useState("");
  let navbar = JSON.parse(localStorage.getItem("navbar"));
  let remotes = navbar.remotes;
  let [remotesWithVersions, setRemotesWithVersions] = useState([]);
  let [lastVersion, setLastVersion] = useState(null);

  const sendUpdate = async (data) => {
    let formData = new FormData();
    formData.append("fileupload", data.file[0]);
    await fetch(`${baseurl}/admin/remoteUpdate`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
      },
    }).then((res) => res.text());
  };

  useEffect(() => {
    fetch(`${baseurl}/modules?table=remoteSWInfo`, {
      method: "GET",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "Accept-Encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        let remoteSWVersion = remotes.map((item, index) => {
          let dbIndex = res.findIndex(
            (dbItem) => dbItem.remoteID === item.remoteID
          );
          if (dbIndex !== -1)
            return {
              remoteID: item.remoteID,
              version: res[dbIndex].swVersion,
              name: item.name,
            };
          return { remoteID: item.remoteID, name: item.name, version: null };
        });
        setRemotesWithVersions(remoteSWVersion);
        let updVersions = res
          .map((item) => item.updVersion)
          .sort((a, b) => b - a);
        setLastVersion(updVersions[0]);
      });
  }, []);

  useEffect(() => {
    if (
      remotesForUpdate.length !==
      remotesWithVersions.filter((item) => item.version >= 2).length
    )
      return setAllRemotesSelected(false);
    let allRemotesIncluded = remotesWithVersions
      .filter((item) => item.version >= 2)
      .map((item) => remotesForUpdate.includes(item.remoteID))
      .reduce((prev, remoteInUpdate) => (remoteInUpdate &= prev), true);
    if (allRemotesIncluded) return setAllRemotesSelected(true);
    setAllRemotesSelected(false);
  }, [remotesForUpdate, remotes]);

  return (
    <div className='card padding10 marginTop10 maxWidth700px'>
      <h3>Update Hochladen</h3>
      <h5 className='textAlignEnd red'>
        Updatefunktion erst ab SW-Version 2.0 verfügbar.
      </h5>
      <div className='gridCol2x1fr gap10'>
        <div>
          <form
            className='marginTop10 placeContentStart'
            onSubmit={handleSubmit(sendUpdate)}
          >
            <h5 className='textAlignStart'>update.zip hochladen</h5>
            <input type='file' {...register("file")} />
            <button type='submit'>Update Hochladen</button>
          </form>
          <div className='marginTop10 gap05 placeContentStart gridCol15ch1fr'>
            <label className='marginToBoAuto'>Neue Version:</label>
            <input
              value={newSWVersion}
              onChange={(e) => {
                setNewSWVersion(e.target.value);
              }}
            ></input>
            <label>Letzte Version:</label>
            <span>{lastVersion}</span>
          </div>
        </div>
        <div>
          <ul className='marginTop10'>
            <h5 className='textAlignStart'>Remotes auswählen...</h5>
            <li
              className='textAlignStart marginTop10 marginBot05 pointer  textDecUnderLine'
              onClick={() =>
                allRemoteSelected
                  ? setRemoteForUpdate([])
                  : setRemoteForUpdate(
                      remotesWithVersions
                        .filter((item) => item.version >= 2)
                        .map((item) => item.remoteID)
                    )
              }
            >
              {allRemoteSelected ? "Keins auswählen" : "Alle auswählen"}
            </li>
            {remotesWithVersions.map((remote, index) => {
              return (
                <li
                  key={index}
                  className={`${
                    remotesForUpdate.includes(remote.remoteID)
                      ? "orange-600"
                      : "gray-600"
                  } textAlignStart marginLeft10 pointer resizeOnHover11`}
                  onClick={() => {
                    if (remote.version < 2) return;
                    let akku = remotesForUpdate;
                    if (akku.includes(remote.remoteID))
                      return setRemoteForUpdate(
                        akku.filter((remID) => remID !== remote.remoteID)
                      );
                    return setRemoteForUpdate([
                      ...remotesForUpdate,
                      remote.remoteID,
                    ]);
                  }}
                >
                  <span
                    className={
                      remote.version >= 2 ? "" : "textDecLineThrough tdColGrey"
                    }
                  >
                    {remote.name != null ? remote.name : remote.remoteID}
                  </span>{" "}
                  <span className='orange-500'>
                    {remote.version ? `- V: ${remote.version}` : ""}
                  </span>
                </li>
              );
            })}
          </ul>
          <span className='marginTop10'>
            <hr orientation='horizontal'></hr>
          </span>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (
                window.confirm(
                  "Wollen sie die gewählten Remotes wirklich updaten?!"
                )
              ) {
                let body = remotesForUpdate.map((item) => {
                  return {
                    database: "remoteDB",
                    table: "remoteSWInfo",
                    method: "MODIFY",
                    selectors: {
                      remoteID: item,
                    },
                    values: {
                      updVersion:
                        newSWVersion != "" ? newSWVersion : lastVersion,
                    },
                  };
                });
                adminPatchAllDB(body);
              }
            }}
          >
            Update Starten
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoteUpdate;
