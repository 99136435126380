import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import AddExistingRemoteToUser from "./AddExistingRemotesToUser";
import AddNewRemotesToUser from "./AddNewRemotesToUser";
import { validateMail } from "../01_Helpers/checkEmailValid";
import { baseurl } from "../01_Helpers/endpoints";
import { refreshLocalStorage } from "../01_Helpers/refreshLocalStorage";

const CreateUser = (props) => {
  //DataFields
  const [email, setEmail] = useState(""); //Muss gecheckt werden!
  const [password, setPassword] = useState(""); //randomGenerated
  const [accountType, setAccountType] = useState(""); //'Betreiber' || 'Benutzer'' || ''
  const [remoteScope, setRemoteScope] = useState([]);
  const [send, triggerSend] = useState(false);
  const [isSent, setIsSent] = useState(false);
  let [userList, setUserList] = useState([]);
  let [resetList, setResetList] = useState(true);
  let setNewPassword = () => {
    let array = Buffer.alloc(4);
    window.crypto.getRandomValues(array);
    let pwdFromArray = array.toString("base64");
    setPassword(pwdFromArray);
  };

  useEffect(() => {
    setNewPassword();
    fetch(`${baseurl}/users/`, {
      method: "GET",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "Accept-Encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => setUserList(res.users))
      .catch((e) => setUserList([]));
  }, []);

  useEffect(() => {}, [send]);

  useEffect(() => {
    if (isSent === false) return;
    if (userList.find((item) => item.email === email) != null) {
      triggerSend(false);
      setIsSent(false);
      return alert("Die E-Mail Adresse ist bereits als Nutzer registriert!");
    }
    if (!validateMail(email)) {
      triggerSend(false);
      setIsSent(false);
      return alert("Bitte geben Sie eine gültige E-Mail Adresse ein.");
    }
    if (!["Betreiber", "Benutzer"].includes(accountType)) {
      triggerSend(false);
      setIsSent(false);
      return alert("Bitte wählen Sie einen Account Typ.");
    }

    let body = {
      userID: uuid(),
      email: email,
      pwd: password,
      accType: accountType,
      remotes: remoteScope,
    };
    fetch(`${baseurl}/users`, {
      method: "POST",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.status !== 201) {
        setIsSent(false);
        triggerSend(false);
        alert(
          "Der Benutzer konnte nicht angelegt werden, bitte überprüfen Sie alle Eingaben und versuchen Sie den Vorgang erneut auszuführen."
        );
      }
      alert(`Der Benutzer ${email} wurde erfolgreich angelegt!`);
      setEmail("");
      setPassword();
      setAccountType("");
      setRemoteScope([]);
      setIsSent(false);
      triggerSend(false);
      setResetList(!resetList);
      refreshLocalStorage();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSent]);

  //States for Rendering

  return (
    <div className='card marginTop10 padding10 maxWidth700px'>
      <h3>Nutzer anlegen</h3>

      <form className='marginTop10  gridCol1x1frOn450px'>
        <div className='flexRow gap10'>
          <div className='gridCol2x1fr maxWidth300px'>
            <label className='marginToBoAuto'>E-Mail:</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div className='gridCol2x1fr maxWidth300px'>
            <label className='marginToBoAuto'>Account Typ</label>
            <select
              value={accountType}
              onChange={(e) => {
                setAccountType(e.target.value);
              }}
            >
              <option value='' disabled>
                Bitte wählen...
              </option>
              <option value='Betreiber'>Betreiber</option>
              <option value='Benutzer'>Benutzer</option>
            </select>
          </div>
          <AddNewRemotesToUser
            resetList={resetList}
            remoteScope={remoteScope}
            setRemoteScope={setRemoteScope}
            send={send}
            isSent={setIsSent}
          />
          <AddExistingRemoteToUser
            resetList={resetList}
            remoteScope={remoteScope}
            setRemoteScope={setRemoteScope}
            send={send}
          />
        </div>
        <div className='span2'>
          <hr orientation='horizontal'></hr>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (window.confirm("Sollen die Eingaben gespeichert werden?"))
              return triggerSend(true);
          }}
        >
          Nutzer erstellen
        </button>
      </form>
    </div>
  );
};

export default CreateUser;
