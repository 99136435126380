import React from "react";

const Datenschutz = (props) => {
  return (
    <div className='frame displayBlock marginTop10 padding10'>
      <h3>Datenschutz</h3>
      <div className='card textAlignStart maxWidth700px marginTop10 padding10 gray-800 displayBlock'>
        <h3>Datenschutzerklärung</h3>
        <h5 className='marginTop10'>1.Datenschutz</h5>
        <p className='marginTop10'>
          Nachfolgend möchten wir Sie über unsere Datenschutzerklärung
          informieren. Sie finden hier Informationen über die Erhebung und
          Verwendung persönlicher Daten bei der Nutzung unserer Webseite. Wir
          beachten dabei das für Deutschland geltende Datenschutzrecht. Sie
          können diese Erklärung jederzeit auf unserer Webseite abrufen.{" "}
        </p>

        <p className='marginTop05'>
          Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im
          Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt
          werden kann.{" "}
        </p>

        <p className='marginTop05'>
          Die Verwendung der Kontaktdaten unseres Impressums zur gewerblichen
          Werbung ist ausdrücklich nicht erwünscht, es sei denn wir hatten zuvor
          unsere schriftliche Einwilligung erteilt oder es besteht bereits eine
          Geschäftsbeziehung. Die Thum EAD GmbH und alle auf dieser Website
          genannten Personen widersprechen hiermit jeder kommerziellen
          Verwendung und Weitergabe ihrer Daten.
        </p>

        <h5 className='marginTop10'>2.Personenbezogene Daten</h5>
        <p className='marginTop05'>
          Sie können unsere Webseite ohne Angabe personenbezogener Daten
          besuchen. Soweit auf unseren Seiten personenbezogene Daten (z.B.
          Anrede, Name, Anschrift, E-Mail-Adresse, Telefonnummer und IP) erhoben
          werden, erfolgt dies, soweit möglich, auf freiwilliger Basis. Diese
          Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte
          weitergegeben. Sofern zwischen Ihnen und uns ein Vertragsverhältnis
          begründet, inhaltlich ausgestaltet oder geändert werden soll oder Sie
          an uns eine Anfrage stellen, erheben und verwenden wir
          personenbezogene Daten von Ihnen, soweit dies zu diesen Zwecken
          erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten und nutzen
          personenbezogene Daten soweit dies erforderlich ist, um Ihnen die
          Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten).
        </p>
        <h5 className='marginTop10'>
          3.Information zur Datenerhebung gem. Artikel 13 DSGVO
        </h5>
        <p className='marginTop05'>
          Sämtliche personenbezogenen Daten werden nur solange gespeichert wie
          dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder
          Abwicklung eines Vertrags) erforderlich ist und beruht auf Artikel 6
          Abs. 1b) DSGVO. Hierbei werden steuer- und handelsrechtliche
          Aufbewahrungsfristen berücksichtigt. Die Daten werden gelöscht, sobald
          sie für den Zweck ihrer Verarbeitung nicht mehr erforderlich sind. Auf
          Anordnung der zuständigen Stellen dürfen wir im Einzelfall Auskunft
          über diese Daten (Bestandsdaten) erteilen, soweit dies für Zwecke der
          Strafverfolgung, zur Gefahrenabwehr, zur Erfüllung der gesetzlichen
          Aufgaben der Verfassungsschutzbehörden oder des Militärischen
          Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen
          Eigentum erforderlich ist.
        </p>
        <h5 className='marginTop10'>4.Cookies und Web-Tracking</h5>
        <p className='marginTop05'>
          Einige Dienste unserer Website verwenden sog. “Cookies”, um die
          Nutzung bestimmter Funktionen zu ermöglichen. Cookies sind kleine
          Textdateien, die der Internet-Browser auf Ihrem Rechner ablegt und
          speichert. Cookies helfen uns festzustellen, wie häufig und von wie
          vielen Nutzern auf unsere Webseiten zugegriffen wird. Darüber hinaus
          tragen sie dazu bei, unser Onlineangebot für Sie möglichst informativ
          und benutzerfreundlich zu gestalten.
        </p>
        <p className='marginTop05'>
          Das Speichern von Cookies können Sie grundsätzlich verhindern, indem
          Sie die Eigenschaft “Cookies akzeptieren” in Ihren
          Browser-Einstellungen deaktivieren; wir weisen Sie jedoch darauf hin,
          dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
          dieser Website vollumfänglich werden nutzen können.
        </p>
        <h5 className='marginTop10'>5.Auskunftsrecht</h5>
        <p className='marginTop05'>
          Sie haben das jederzeitige Recht, sich unentgeltlich und unverzüglich
          über die zu Ihrer Person erhobenen Daten zu erkundigen. Sie haben das
          jederzeitige Recht, Ihre Zustimmung zur Verwendung Ihrer angegeben
          persönlichen Daten mit Wirkung für die Zukunft zu widerrufen. Zur
          Auskunftserteilung wenden Sie sich bitte an den Anbieter unter den
          Kontaktdaten im Impressum.
        </p>
      </div>
    </div>
  );
};

export default Datenschutz;
