import React, { useEffect} from "react";
import { Outlet, useNavigate, Link, useLocation } from "react-router-dom";
import { company } from "../config";
global.Buffer = global.Buffer || require("buffer").Buffer;

function Main(props) {
  let loggedIn = props.loggedIn;
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if(location.pathname==='/login') return;
    if(location.pathname==='/Datenschutz') return;
    if(location.pathname==='/Impressum') return;
    if (localStorage.getItem("token") == null) return navigate("/login");
    if (!loggedIn) return navigate("/login");
    if(location.pathname === '/') return navigate("/dashboard");
  }, [loggedIn, location]);

  return (
    <div className='Main padding10'>
      <Outlet />
      <div className=' padding10 marginTop05 flexRow flexSpaceBetween'>
        <p className='gray-800'>{company === 'thum' ? "© Thum EAD GmbH" : "© Priosafe GmbH"}</p>
        <ul className='gray-400 flexRow flexSpaceEvenly gap10 marginRight10'>
          <li className='textUnderline'>
            {" "}
            <Link to='/Datenschutz'>Datenschutzerklärung</Link>
          </li>
          <li className='textUnderline'>
            <Link to='/Impressum'>Impressum</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Main;
