import React from "react";

const DeleteRemote = (props) => {
  const {
    deleteAllRemoteData,
    setDeleteAllRemoteData,
    deleteAnlagenData,
    setDeleAnlagenData,
    deleteRemoteID,
    setDeleteRemoteID,
  } = props;
  return (
    <>
      <h5 className='textAlignStart'>Remote löschen:</h5>
      <div className='gridCol02ch1fr marginToBoAuto gap05 alignItemsCenter'>
        <input className='marginToBoAuto' type='checkbox' checked={deleteAllRemoteData} onChange={e => setDeleteAllRemoteData(e.target.checked)}/>
        <label className='marginToBoAuto'>
          Gerätedaten: Alle Daten löschen!
        </label>
      </div>
      <div className='gridCol02ch1fr marginToBoAuto gap05 alignItemsCenter'>
        <input className='marginToBoAuto' type='checkbox' checked={deleteAnlagenData} onChange={e => setDeleAnlagenData(e.target.checked)}/>
        <label className='marginToBoAuto'>
          Zugehörige Anlagen: Alle Daten Löschen!
        </label>
      </div>
      <div className='gridCol02ch1fr marginToBoAuto gap05 alignItemsCenter'>
        <input className='marginToBoAuto' type='checkbox' checked={deleteRemoteID} onChange={e => setDeleteRemoteID(e.target.checked)}/>
        <label className='marginToBoAuto'>
          Raspi defekt: RemoteID aus Datenbank löschen!
        </label>
      </div>
    </>
  );
};

export default DeleteRemote;
