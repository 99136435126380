import React from "react";
import { useParams } from "react-router-dom";
import { getAnlagenName } from "../01_Helpers/nameHelpers";
import AnlagenDashBoard from "../AnlageDashStuff/AnlagenDashBoard";

const AnlagenIDFrame = (props) => {
  let { anlagenID } = useParams();
  let anlagenName = getAnlagenName(anlagenID);
  return (
    <div>
      <div className='anlagenDashboardHeader'>
        <h3>
          {anlagenID} {anlagenName}
        </h3>
      </div>
      <AnlagenDashBoard />
    </div>
  );
};

export default AnlagenIDFrame;
