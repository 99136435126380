import React from "react";
import { decodeDaten } from "../01_Helpers/anlagenDatenHelper";

const AnlagenDatenWidget = (props) => {
  let daten = props.daten;
  let decodedDaten = decodeDaten(daten);
  let keys = Object.keys(decodedDaten);
  let vals = Object.values(decodedDaten);
  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Daten</h3>
      </div>
      <div className='anlagenWidgetBody'>
        {keys.map((item, index, array) => {
          return (
            <div className='DatenEntry marginTop05' key={index}>
              <span className='gray-800 bold'>{item}</span>
              <span className='textAlignCenter'>{vals[index]}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnlagenDatenWidget;
