import React, { useState, useEffect } from "react";
import {
  adminPatchAllDB,
  getRemoteAndNickNames,
  getRemoteTable,
} from "../01_Helpers/fetchStuff";
import ToolPanel from "../02_Tools/ToolPanel";
import RemoteList from "./RemoteList";

const DeliverRemote = (props) => {
  const [remoteList, setRemoteList] = useState([]);
  const [activeRemote, setActiveRemote] = useState(null);
  const [activeAnlage, setActiveAnlage] = useState(null);
  const [activeRemoteName, setActiveRemoteName] = useState("");
  const [remoteAnlage, setRemoteAnlage] = useState([]);
  const [resetAnlage, setResetAnlage] = useState(false);
  const [resetTemp, setResetTemp] = useState(false);

  useEffect(() => {
    getRemoteAndNickNames()
      .then((res) => res.json())
      .then((res) => setRemoteList(res));
    getRemoteTable("remoteAnlage")
      .then((res) => res.json())
      .then((res) => setRemoteAnlage(res));
  }, []);

  useEffect(() => {
    if (activeRemote == null) return setActiveAnlage(null);
    let remoteNickEntry = remoteList.find(
      (item) => item.remoteID === activeRemote
    );
    if (remoteNickEntry) setActiveRemoteName(remoteNickEntry.nickName);
    let activeAnlage = remoteAnlage.find(
      (item) => item.remoteID === activeRemote
    ).anlagenID;
    setActiveAnlage(activeAnlage);
    setResetAnlage(false);
    setResetTemp(false);
  }, [activeRemote]);

  return (
    <div className='card maxWidth600px'>
      <h3>Gerät ausliefern</h3>
      <div className='flexRow flexStart gap10 marginTop10'>
        <div>
          <h5 className='textAlignStart'>Geräte:</h5>
          <RemoteList
            remoteList={remoteList}
            activeRemote={activeRemote}
            setActiveRemote={setActiveRemote}
          />
        </div>

        <div className='placeContentStart gap10 '>
          <h5 className='textAlignStart'>Einstellungen: {activeRemoteName}</h5>
          {activeRemote && (
            <div>
              <div className='gridCol04ch1fr'>
                <span className=''>
                  <input
                    type='checkbox'
                    checked={resetTemp}
                    onChange={(e) => setResetTemp(!resetTemp)}
                  ></input>
                </span>
                <label className='marginToBoAuto'>
                  Temperaturdaten zurücksetzen
                </label>
                <span className=''>
                  <input
                    type='checkbox'
                    checked={resetAnlage}
                    onChange={(e) => setResetAnlage(!resetAnlage)}
                  ></input>
                </span>
                <label className='marginToBoAuto'>
                  Anlagendaten zurücksetzen
                </label>
              </div>
              <span className='marginTop10'>
                <hr orientation='horizontal'></hr>
              </span>
              <div>
                <button
                  className='floatRight'
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      window.confirm(
                        "Wollen sie die ausgewählten Daten wirklich zurücksetzen?"
                      )
                    ) {
                      let patchBody = [];
                      if (resetAnlage) {
                        patchBody.push({
                          method: "DELETE",
                          database: "anlagenDB",
                          selectors: {
                            anlagenID: activeAnlage,
                          },
                          table: null,
                        });
                      }
                      if (resetTemp) {
                        patchBody.push({
                          method: "DELETE",
                          database: "remoteDB",
                          selectors: {
                            remoteID: activeRemote,
                          },
                          table: ["tempRemote"],
                        });
                      }
                      console.log(patchBody);
                      adminPatchAllDB(patchBody);
                    }
                  }}
                >
                  Auswahl zurücksetzen
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliverRemote;
