import React from "react";

const ImpressumPriosafe = (props) => {
  return (
    <div className='frame displayBlock marginTop10 padding10'>
      <h3>Impressum</h3>
      <div className='card textAlignStart maxWidth700px marginTop10 padding10 gray-800 displayBlock'>
        <h5 className='marginTop10'>Priosafe GmbH</h5>
        <p className='marginTop05'>Marie-Curie-Straße 13</p>
        <p className='marginTop05'> 40822 Mettmann</p>
        <p className='marginTop20'>info@priosafe.de </p>
        <p className='marginTop05'>T: +49 (0)2104 80029-0</p>
        <p className='marginTop05'>F: +49 (0)2104 80029-20</p>
        <p className='marginTop20'>Geschäftsführer: Peter Steiner</p>
        <p className='marginTop05'>Inhaltlich verantwortlich: Peter Steiner</p>
        <p className='marginTop05'>Amtsgericht: Wuppertal HRB 28653 </p>
        <p className='marginTop05'>Ust-IdNr.: DE 298094862 </p>
        <p className='marginTop05'>Steuernummer: 115/5705/1000 ​</p>
        <p className='marginTop05'>Registernummer: HRB-Nr. 25 601</p>
        <p className='marginTop05'>UST-ID Nr.: DE 119 497 266</p>
      </div>
    </div>
  );
};

export default ImpressumPriosafe;
