import React, { useState, useEffect } from "react";
import Searchbar from "../02_Tools/SearchBar";
import ToolPanel from "../02_Tools/ToolPanel";
import RemoteList from "./RemoteList";
import RemoteOverview from "./RemoteOverView";
import RemoteUserList from "./RemoteUserList";
import {
  adminPatchAllDB,
  getRemoteAndNickNames,
  getRemoteTable,
  getUserDB,
} from "../01_Helpers/fetchStuff";
import DeleteRemote from "./DeleteRemote";
import { v4 as uuid } from "uuid";
import { refreshLocalStorage } from "../01_Helpers/refreshLocalStorage";

const EditRemote = (props) => {
  let [editMode, setEditMode] = useState(false);
  let [deleteMode, setDeleteMode] = useState(false);
  let [remoteList, setRemoteList] = useState([]);
  let [activeRemote, setActiveRemote] = useState("");
  let [remoteAndAnlagenIDs, setRemoteAndAnlagenIDs] = useState([]);
  let [userList, setUserList] = useState([]);
  let [patchBody, setPatchBody] = useState([]);
  let [serialNumber, setSerialNumber] = useState("");
  let [serialNumbers, setSerialNumbers] = useState("");

  //DELETE OPTIONS
  let [deleteAllRemoteData, setDeleteAllRemoteData] = useState(false);
  let [deleteAnlagenData, setDeleAnlagenData] = useState(false);
  let [deleteRemoteID, setDeleteRemoteID] = useState(false);

  useEffect(() => {
    if (!deleteMode)
      return setPatchBody(
        patchBody.filter((request) => request.method !== "DELETE")
      );

    let patchBodyNew = [];
    if (deleteAllRemoteData) {
      patchBodyNew.push({
        id: uuid(),
        method: "DELETE",
        database: "remoteDB",
        table: null,
        selectors: { remoteID: activeRemote },
      });
      patchBodyNew.push({
        id: uuid(),
        method: "DELETE",
        database: "userDB",
        table: ["UserRemote"],
        selectors: { remoteID: activeRemote },
      });
    }
    if (deleteAnlagenData) {
      let anlagenID = remoteAndAnlagenIDs.find(
        (item) => item.remoteID === activeRemote
      ).anlagenID;
      patchBodyNew.push({
        id: uuid(),
        method: "DELETE",
        database: "anlagenDB",
        table: null,
        selectors: { anlagenID: anlagenID },
      });
    }
    if (!deleteRemoteID) {
      patchBodyNew.push({
        id: uuid(),
        method: "ADD",
        database: "remoteDB",
        table: "newRemote",
        values: { remoteID: activeRemote },
      });
    }
    setPatchBody(patchBodyNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAllRemoteData, deleteAnlagenData, deleteRemoteID, deleteMode]);

  //Edit Options
  let [editAnlagenID, setEditAnlagenID] = useState("");
  let [editNickName, setEditNickName] = useState("");
  let [editSerialNumber, setEditSerialNumber] = useState("");

  let updateLists = () => {
    getRemoteTable("remoteAnlage")
      .then((res) => res.json())
      .then((res) =>
        Array.isArray(res)
          ? setRemoteAndAnlagenIDs(res)
          : setRemoteAndAnlagenIDs([res])
      );
    getRemoteTable("remoteSerialNumber")
      .then((res) => res.json())
      .then((res) =>
        Array.isArray(res) ? setSerialNumbers(res) : setSerialNumbers([res])
      );
    getRemoteAndNickNames()
      .then((res) => res.json())
      .then((res) => setRemoteList(res));
    getUserDB()
      .then((res) => res.json())
      .then((res) => setUserList(res.users));
  };

  const resetAllData = () => {
    setEditMode(false);
    setDeleteMode(false);
    setActiveRemote("");
    setPatchBody([]);
    setDeleteAllRemoteData(false);
    setDeleAnlagenData(false);
    setDeleteRemoteID(false);
    setEditAnlagenID("");
    setEditNickName("");
  };

  useEffect(() => {
    updateLists();
  }, []);

  useEffect(() => {
    setEditMode(false);
    setDeleteMode(false);
  }, [activeRemote]);

  return (
    <div className='card padding10 marginTop10 maxWidth700px'>
      <h3>Remote bearbeiten</h3>

      <div className='flexRow gap20'>
        <div className='marginTop10 placeContentStart'>
          <h5 className='textAlignStart'>Remote Liste</h5>
          <Searchbar></Searchbar>
          <RemoteList
            remoteList={remoteList}
            setActiveRemote={setActiveRemote}
            activeRemote={activeRemote}
          />
        </div>

        <div className='marginTop10'>
          {deleteMode ? (
            <>
              <DeleteRemote
                deleteAllRemoteData={deleteAllRemoteData}
                setDeleteAllRemoteData={setDeleteAllRemoteData}
                deleteAnlagenData={deleteAnlagenData}
                setDeleAnlagenData={setDeleAnlagenData}
                deleteRemoteID={deleteRemoteID}
                setDeleteRemoteID={setDeleteRemoteID}
              />
            </>
          ) : (
            <>
              <RemoteOverview
                remoteAndAnlagenIDs={remoteAndAnlagenIDs}
                remoteList={remoteList}
                activeRemote={activeRemote}
                editMode={editMode}
                editNickName={editNickName}
                setEditNickName={setEditNickName}
                editAnlagenID={editAnlagenID}
                setEditAnlagenID={setEditAnlagenID}
                patchBody={patchBody}
                setPatchBody={setPatchBody}
                serialNumber={serialNumber}
                serialNumbers={serialNumbers}
                setSerialNumber={setSerialNumber}
                editSerialNumber={editSerialNumber}
                setEditSerialNumber={setEditSerialNumber}
              />
              <div className='marginTop10'>
                <RemoteUserList
                  userList={userList}
                  activeRemote={activeRemote}
                  editMode={editMode}
                  patchBody={patchBody}
                  setPatchBody={setPatchBody}
                />
              </div>
            </>
          )}
          {activeRemote.length !== 0 && (
            <>
              <span className='marginTop10'>
                <hr orientation='horizontal' />
              </span>
              <ToolPanel
                saveChanges={() => {
                  if (
                    window.confirm(
                      "Wollen Sie die Eingaben wirklich speichern?"
                    )
                  ) {
                    adminPatchAllDB(patchBody);
                    userList.forEach((user) => delete user.deleteID);
                    resetAllData();
                    updateLists();
                    refreshLocalStorage();
                  }
                }}
                discardChanges={() => {
                  userList.forEach((user) => delete user.deleteID);
                  resetAllData();
                }}
                deleteItem={() => {
                  setDeleteMode(true);
                  setEditMode(true);
                }}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditRemote;
