exports.decodeImpedanzen = (data) => {
  let dataArray = [];
  if (!Array.isArray(data)) dataArray.push(data);
  else dataArray = data;
  let returnArray = dataArray.map((item) => {
    let dataHex = new Buffer.from(item.impedanz, "hex");
    return {
      messlinie: dataHex[0] + 1,
      linienIndex: dataHex[1] === 0 ? "A" : "B",
      z1kHz: (dataHex[2] << 8) + dataHex[3],
      zRef: (dataHex[4] << 8) + dataHex[5],
      zAkt: (dataHex[6] << 8) + dataHex[7],
    };
  });
  return returnArray;
};
