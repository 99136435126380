exports.decodeEV = (daten) => {
  let dataBuffer = new Buffer.from(daten, "hex");
  let amps = [];
  const getEVErrors = (dataByte) => {
    let errorString = "";
    if (dataByte & 0x01) errorString += "NF1, ";
    if (dataByte & 0x02) errorString += "NF2, ";
    if (dataByte & 0x04) errorString += "NF3, ";
    if (dataByte & 0x08) errorString += "NF4, ";
    if (dataByte & 0x10) errorString += "EVH, ";
    if (dataByte & 0x20) errorString += "Pilot int, ";
    if (dataByte & 0x40) errorString += "EV2 , ";
    if (dataByte & 0x80) errorString += "EV1 , ";
    return errorString.substring(0, errorString.length - 2);
  };

  for (let i = 0; i < dataBuffer.length; i++) {
    if (dataBuffer[i] === 0) continue;
    amps.push({
      ampID: i + 1,
      ampErrors: getEVErrors(dataBuffer[i]),
    });
  }
  return amps;
};
