import React, { useEffect, useState } from "react";
import { baseurl } from "../01_Helpers/endpoints";
import Searchbar from "../02_Tools/SearchBar";
const UserListWithSearchBar = (props) => {
  let [userList, setUserList] = useState([]);
  const { setActiveUser, activeUser } = props;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${baseurl}/users`, {
      signal: signal,
      method: "GET",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "Accept-Encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => setUserList(res.users));

    return function cleanup() {
      controller.abort();
    };
  }, []);

  return (
    <>
      <div className='marginTop10 placeContentStart'>
        <h5 className='textAlignStart'>User Liste</h5>
        <Searchbar></Searchbar>
        <ul className='textAlignStart marginTop05 gray-600'>
          {userList.map((user, index) => (
            <li
              className={
                user.userID === activeUser?.userID
                  ? "resizeOnHover11 pointer orange-800"
                  : "resizeOnHover11 pointer"
              }
              onClick={(e) => {
                setActiveUser(user);
              }}
              key={index}
            >
              {user.email}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default UserListWithSearchBar;
