import React from "react";
import { Outlet } from "react-router-dom";

const SettingsMainFrame = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default SettingsMainFrame;
