import React, { useState, useEffect } from "react";
import { adminPatchAllDB, getRemoteTable } from "../01_Helpers/fetchStuff";

let getRemoteName = (remoteID) => {
  let navbar = JSON.parse(localStorage.getItem("navbar"));
  let remote = navbar.remotes.find((item) => item.remoteID === remoteID);
  return remote.name;
};

let makeDateTime = (timestamp) => {
  let date = new Date(parseInt(timestamp));
  console.log(date);
  return date.toLocaleString();
};

const ErrorDisplay = (props) => {
  const [remoteErrorMessages, setRemoteErrorMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeRemoteID, setActiveRemoteID] = useState("");
  const [activeTimeStamp, setActiveTimeStamp] = useState("");
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    getRemoteTable("remoteErrorHistory")
      .then((res) => res.json())
      .then((res) => {
        let ordered = res.sort((a, b) => b.unixtimestamp - a.unixtimestamp);
        setRemoteErrorMessages(ordered);
      });
  }, [refresh]);

  return (
    <div className='card gridCol25ch1fr'>
      <div>
        <h5 className='textAlignStart'>Fehlerliste:</h5>
        <ul>
          {remoteErrorMessages.map((item, index) => {
            return (
              <li
                className='marginTop05 resizeOnHover11 pointer'
                key={index}
                onClick={() => {
                  setActiveRemoteID(item.remoteID);
                  setActiveTimeStamp(item.unixtimestamp);
                  setErrorMessage(item.error);
                }}
              >
                {getRemoteName(item.remoteID)}
                <br />
                {makeDateTime(item.unixtimestamp)}
              </li>
            );
          })}
        </ul>
      </div>
      <div className='card maxHeight500px'>
        <h5>Fehlermeldung</h5>
        <p>{errorMessage}</p>
        <span>
          <hr orientation='horizontal'></hr>
        </span>
        <button
          onClick={() => {
            let patchBody = [
              {
                database: "remoteDB",
                table: "remoteErrorHistory",
                method: "DELETE",
                selectors: {
                  remoteID: activeRemoteID,
                  unixtimestamp: activeTimeStamp,
                },
              },
            ];
            adminPatchAllDB(patchBody).then(setRefresh(!refresh));
          }}
        >
          Löschen
        </button>
      </div>
    </div>
  );
};

export default ErrorDisplay;
