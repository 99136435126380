import Main from "./MainFrame/Main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginForm from "./MainFrame/Login";
import React, { useEffect, useState } from "react";
import SettingsMainFrame from "./Settings/SettingsMainFrame";
import RemoteMainFrame from "./Settings/RemoteMainFrame";
import RemoteIDFrame from "./Settings/remoteIDFrame";
import RemoteInputSettings from "./Settings/RemoteInputSettingsFrame";
import RemoteOutputSettings from "./Settings/RemoteOutputSettingsFrame";
import RemoteTemperatureSettingsFrame from "./Settings/RemoteTemperaturSettingsFrame";
import AnlagenIDFrame from "./Settings/AnlagenIDFrame";
import RemoteDashboard from "./MainFrame/RemoteDashboard";
import thumLogo from "./transThumMit.png";
import prioSafeLogo from "./prioSafe.png";
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { HiOutlineMenu } from "react-icons/hi";
import AdminFrame from "./Admin/AdminFrame";
import Nav from "./Navigation/NavLR";
import AnlagenOverview from "./AnlageDashStuff/AnlagenOverview";
import RemoteOverview from "./RemoteOverview/RemoteOverview";
import NotificationsMainFrame from "./Notification/NotificationsMainFrame";
import RemoteSingleOverview from "./Settings/RemoteSingleOverview";
import Datenschutz from "./DatenUndImpressum/Datenschutz";
import Impressum from "./DatenUndImpressum/Impressum";
import UserFrame from "./User/UserFrame";
import UserSettings from "./User/UserSettings";
import EditUser from "./Admin/EditUser";
import EditRemote from "./Admin/EditRemote";
import CreateUser from "./Admin/CreateUser";
import AdminLandingPage from "./Admin/AdminLandingPage";
import NotificationLandingPage from "./Notification/NotificationLandingPage";
import UserLandingPage from "./User/UserLandingPage";
import Adressbuch from "./Notification/Adressbuch";
import NotificationEvents from "./Notification/NotificationEvents";
import RemoteUpdate from "./Admin/RemoteUpdate";
import "./Colors.css";

import { company } from "./config";
import DatenschutzPriosafe from "./DatenUndImpressum/DatenschutzPriosafe";
import ImpressumPriosafe from "./DatenUndImpressum/ImpressumPriosafe";
import SettingsLandingPage from "./Settings/SettingsLandingPage";
import RemoteUserSettings from "./Settings/RemoteUserSettings";
import CreateNewModule from "./Admin/CreateNewModule";
import DeliverRemote from "./Admin/DeliverRemote";
import ErrorDisplay from "./Admin/ErrorDisplay";
const setTheme = (theme) => (document.documentElement.className = theme);
setTheme(company);

function App() {
  let token = JSON.parse(localStorage.getItem("token"));
  const [scope, setScope] = useState(JSON.parse(localStorage.getItem("scope")));
  const [loggedIn, setLoggedIn] = useState(token ? true : false);
  const [remotes, setRemotes] = useState(scope ? scope.remote : []);
  const [navbar, setNavbar] = useState(null);

  window.onstorage = () => {
    console.log("Storage");
    let newScope = JSON.parse(localStorage.getItem("scope"));
    setScope(newScope != null ? newScope : {});
    setRemotes(newScope?.remote);
    setNavbar(JSON.parse(localStorage.getItem("navbar")));
  };

  useEffect(() => {
    window.onresize = () => {
      let nav = document.getElementById("sideNav");
      nav.style.display = null;
    };
    window.addEventListener("resize", () => {
      let rootElement = document.querySelector(":root");
      let innerHeight = window.innerHeight + "px";
      rootElement.style.setProperty("--real-height", innerHeight);
      console.log("onresize");
    });
  }, []);
  return (
    <div className='App'>
      <Router>
        <Link to='/' id='logoLink' className='topnavLogo'>
          <img
            src={""}
            alt='Logo'
            style={{ cursor: "pointer" }}
            onClick={() => {
              let sideNav = document.getElementById("sideNav");
              let width = window.innerWidth;
              if (width <= 900 && sideNav != null)
                sideNav.style.display = "none";
            }}
          ></img>
        </Link>
        <div className='topnavtitle'>
          <h1>Remote Portal</h1>
        </div>
        <div className='logoutButton'>
          {loggedIn && (
            <Link
              to='/'
              className='logoutButtonLink'
              onClick={() => {
                localStorage.clear();
                setNavbar(null);
                setLoggedIn(false);
              }}
            >
              <FiLogOut color='red' size='1.5em'></FiLogOut>
              <span>Logout</span>
            </Link>
          )}
        </div>
        {navbar != null && (
          <div className='menuButton'>
            <HiOutlineMenu
              onClick={() => {
                let sideNav = document.getElementById("sideNav");
                sideNav.style.display =
                  sideNav.style.display === "none" ||
                  sideNav.style.display === ""
                    ? "flex"
                    : "none";
              }}
            />
          </div>
        )}

        <Nav
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          navbar={navbar}
          setNavbar={setNavbar}
        />
        <Routes>
          <Route path='' element={<Main loggedIn={loggedIn} />}>
            <Route
              path='/login'
              element={
                <LoginForm
                  setRemotes={setRemotes}
                  setLoggedIn={setLoggedIn}
                  setScope={setScope}
                  setNavbar={setNavbar}
                />
              }
            />
            <Route
              path='/dashboard'
              element={
                <RemoteDashboard
                  remotes={remotes}
                  loggedIn={loggedIn}
                  scope={scope}
                  setRemotes={setRemotes}
                />
              }
            />
            <Route
              path='/datenschutz'
              element={
                company === "thum" ? <Datenschutz /> : <DatenschutzPriosafe />
              }
            ></Route>
            <Route
              path='/impressum'
              element={
                company === "thum" ? <Impressum /> : <ImpressumPriosafe />
              }
            ></Route>
            <Route path='/admin' element={<AdminFrame></AdminFrame>}>
              <Route path='' element={<AdminLandingPage />} />
              <Route path='createuser' element={<CreateUser />} />
              <Route path='edituser' element={<EditUser />} />
              <Route path='editremote' element={<EditRemote />} />
              <Route path='updateremotes' element={<RemoteUpdate />} />
              <Route path='createremote' element={<CreateNewModule />} />
              <Route path='deliverremote' element={<DeliverRemote />} />
              <Route path='errordisplay' element={<ErrorDisplay />} />
            </Route>
            <Route
              path='/settings'
              element={<SettingsMainFrame scope={scope} />}
            >
              <Route path='modules' element={<RemoteMainFrame scope={scope} />}>
                <Route path='' element={<RemoteOverview />}></Route>
                <Route
                  path=':remoteID'
                  element={<RemoteIDFrame scope={scope} />}
                >
                  <Route path='' element={<RemoteSingleOverview />} />
                  <Route path='inputs'>
                    <Route
                      path=''
                      element={<RemoteInputSettings scope={scope} />}
                    />
                  </Route>
                  <Route path='outputs' element={<RemoteOutputSettings />} />
                  <Route
                    path='temperatur'
                    element={<RemoteTemperatureSettingsFrame />}
                  />
                  <Route
                    path='notifications'
                    element={<NotificationEvents />}
                  />
                  <Route path='settings' element={<RemoteUserSettings />} />
                </Route>
              </Route>
              <Route path='anlagen' element={<RemoteMainFrame scope={scope} />}>
                <Route path='' element={<AnlagenOverview />}></Route>
                <Route path=':anlagenID/*' element={<AnlagenIDFrame />} />
              </Route>
              <Route path='notifications' element={<NotificationsMainFrame />}>
                <Route path='' element={<NotificationLandingPage />} />

                <Route path='remotes' element={<NotificationEvents />}></Route>
              </Route>
              <Route path='user' element={<UserFrame />}>
                <Route path='' element={<UserLandingPage />} />
                <Route path='groups' element={<Adressbuch />}></Route>
                <Route path='user' element={<UserSettings />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export { App };
