import React, {  useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputWidget from "./InputWidget";
import "./InputSettings.css";

const RemoteInputSettings = (props) => {
  let { remoteID } = useParams();
  let [inputSettings, setInputSettings] = useState([]);
  let scope = JSON.parse(localStorage.getItem("scope"));
  let token = JSON.parse(localStorage.getItem("token"));

  let dummyData = [];
  for (let i = 0; i < 8; i++) {
    dummyData.push({
      remoteID: remoteID,
      remoteInputIndex: i,
      remoteInputName: `Eingang ${i + 1}`,
      remoteInputMessage: `Das ist die Nachricht von Eingang ${i + 1}`,
      notification: 0,
    });
  }
  useEffect(() => {
    let remoteScope = scope.remote.find((item) => item.remoteID === remoteID);

    let InputConfig = async () => {
      return await fetch(`${remoteScope.href}?table=remoteInputConfig`, {
        headers: {
          Authorization: token,
        },
        method: "GET",
      }).then(async (res) => {
        return await res.json();
      });
    };

    InputConfig().then((res) => {
      if (res.remoteInputConfig.length === 8)
        return setInputSettings(res.remoteInputConfig);

      let extendToEightInputs = dummyData.map((item, index, array) => {
        let setInputFromDataBase = res.remoteInputConfig.find(
          (dbItem) => item.remoteInputIndex === dbItem.remoteInputIndex
        );
        return setInputFromDataBase != null ? setInputFromDataBase : item;
      });
      return setInputSettings(extendToEightInputs);
    });
  }, []);

  return (
    <div className='remoteInputSettingsBody gap10 padding10'>
      {inputSettings.map((item, index) => {
        return <InputWidget key={index} input={item} />;
      })}
    </div>
  );
};

export default RemoteInputSettings;
