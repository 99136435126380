exports.validateMail = (adress) => {
  if (adress.includes(" ")) return false;
  let splitAdressAtAt = adress.split("@");
  if (splitAdressAtAt.length !== 2) return false;
  let domainPartSeperatedByDot = splitAdressAtAt[1].split(".");
  if (domainPartSeperatedByDot.length < 2) return false;
  if (splitAdressAtAt.length === 0) return false;
  let zeroLengthDomainParts = domainPartSeperatedByDot.map((item) =>
  item.length === 0 ?  false : true
  );
  if (zeroLengthDomainParts.includes(false)) return false;
  return true;
};
