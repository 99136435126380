import React from "react";

const SaaNotificationPanel = (props) => {
  const { saaState, setSaaState } = props;
  return (
    <>
      <label className='marginToBoAuto'>Anlagen Status</label>
      <select value={saaState} onChange={(e) => setSaaState(e.target.value)}>
        <option value='' selected disabled>
          Bitte wählen
        </option>
        <option value='error'>Störung</option>
        <option value='alarm'>Alarm</option>
      </select>
    </>
  );
};

export default SaaNotificationPanel;
