const getDays = (data) => {
  let days = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
  let daystring = "";
  for (let i = 0, mask = 1; mask <= 0b10000000; mask <<= 1, i++) {
    if (data & mask) {
      daystring += days[i] + " ";
    }
  }
  return daystring;
};

exports.decodeSchaltzeiten = (data) => {
  let genFormTime = (hour, min) => {
    let stringhour = "";
    let stringmin = "";
    if (hour < 10) stringhour = "0" + hour.toString();
    else stringhour = hour.toString();
    if (min < 10) stringmin = "0" + min.toString();
    else stringmin = min.toString();
    return `${stringhour}:${stringmin}`;
  };
  let dataHex = new Buffer.from(data, "hex");
  let schaltzeitenArray = [];
  for (let i = 0; i < dataHex.length; i += 4) {
    if (dataHex[i] < 0 || dataHex[i] > 23) continue;
    if (dataHex[i + 1] < 0 || dataHex[i + 1] > 59) continue;
    schaltzeitenArray.push({
      uhrzeit: genFormTime(dataHex[i], dataHex[i + 1]),
      tage: getDays(dataHex[i + 2]),
      makronum: dataHex[i + 3] - 1,
    });
  }

  return schaltzeitenArray;
};
