import React from "react";

const Impressum = (props) => {
  return (
    <div className='frame displayBlock marginTop10 padding10'>
      <h3>Impressum</h3>
      <div className='card textAlignStart maxWidth700px marginTop10 padding10 gray-800 displayBlock'>
        <h5 className='marginTop10'>THUM EAD GmbH</h5>
        <p className='marginTop05'>In der Steele 20</p>
        <p className='marginTop05'>40599 Düsseldorf</p>
        <p className='marginTop20'>Telefon 0211 74 94 80</p>
        <p className='marginTop05'>Telefax 0211 74 94 849</p>
        <p className='marginTop05'>info@thum-ead.de</p>
        <p className='marginTop05'>www..de</p>
        <h5 className='marginTop20'>Für den Inhalt verantwortlich:</h5>

        <p className='marginTop05'>THUM EAD GmbH</p>
        <p className='marginTop05'>Gerichtsstand: Düsseldorf</p>
        <p className='marginTop05'>Registergericht: Amtsgericht Düsseldorf</p>
        <p className='marginTop05'>Registernummer: HRB-Nr. 25 601</p>
        <p className='marginTop05'>UST-ID Nr.: DE 119 497 266</p>
      </div>
    </div>
  );
};

export default Impressum;
