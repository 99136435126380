import React, { useEffect } from "react";
import { decodeKomponenten } from "../01_Helpers/anlagenKomponentenStoerungHelper";

const AnlagenKomponentenWidget = (props) => {
  let komponenten = props.komponenten;
  let komponentenDecoded = decodeKomponenten(komponenten);
  useEffect(() => {
    komponentenDecoded = decodeKomponenten(komponenten);
    if (komponentenDecoded.length === 0) props.setHideKomponenten(true);
    else props.setHideKomponenten(false);
  }, [komponenten]);

  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Komponenten Störungen</h3>
      </div>
      <div className='anlagenWidgetBody'>
        {komponentenDecoded.map((item, index, array) => {
          return (
            <div key={index} className='gridCol07ch1fr gap05 marginTop05'>
              <span className='komponentenID gray-800 bold paddingLeft05'>
                ID {item.komponentenid}
              </span>
              <span className='komponentenTyp'>{item.komponente}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnlagenKomponentenWidget;
