import React, { useEffect, useState } from "react";
import AddExistingRemoteToUser from "./AddExistingRemotesToUser";
import AddNewRemotesToUser from "./AddNewRemotesToUser";
import { FaTimes } from "react-icons/fa";

const AddRemoteToUserPanel = (props) => {
  let { editRemoteScope, setEditRemoteScope, send, isSent, resetList } = props;
  let [remoteCategory, setRemoteCategory] = useState(null);

  useEffect(() => {
    if (remoteCategory == null) isSent(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send]);
  return (
    <div className='marginTop10'>
      <h5 className='textAlignStart'>Remote hinzufügen: </h5>
      {remoteCategory == null && (
        <div className='marginTop10 card flexRow flexSpaceEvenly'>
          <label
            className='resizeOnHover11 pointer textDecUnderLine'
            onClick={(e) => setRemoteCategory("new")}
          >
            Hinzufügen...
          </label>
        </div>
      )}
      <div
        className='width100pro gridCol2x1fr gap10'
        style={{ position: "relative" }}
      >
        {remoteCategory !== null && (
          <span
            className='red textAlignEnd marginRight10'
            style={{ position: "absolute", top: "-1rem", right: "0" }}
          >
            <FaTimes
              className='fontSize11 resizeWithOpacity pointer'
              style={{ transform: "translateY(2rem)" }}
              onClick={(e) => setRemoteCategory(null)}
            ></FaTimes>
          </span>
        )}
        {remoteCategory !== null && (
          <AddNewRemotesToUser
            resetList={resetList}
            remoteScope={editRemoteScope}
            setRemoteScope={setEditRemoteScope}
            send={send}
            isSent={isSent}
          />
        )}
        {remoteCategory !== null && (
          <AddExistingRemoteToUser
            resetList={resetList}
            remoteScope={editRemoteScope}
            setRemoteScope={setEditRemoteScope}
          />
        )}
      </div>
    </div>
  );
};

export default AddRemoteToUserPanel;
