import React from "react";
import "./ToolTip.css";
import { FaTimes, FaPen } from "react-icons/fa";
/*
Props:
head = Array of Strings
body = Array of Objects [{keyHead1: val, keyHead2: val, ... , keyHeadN: val, id: ''}]
classNames = String
setEditOption = () => {} setState to an editable ID
setDeleteOptionOption = () => {} setState to an ID for deletion
activeOption = ID of active option!
*/

const DisplayTable = (props) => {
  const {
    head,
    body,
    classNames,
    setEditOption,
    setDeleteOption,
  } = props;
  return (
    <table className={classNames}>
      <thead>
        <tr>
          <th>Tools</th>
          {head.map((item) => (
            <th>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody className='contentBox'>
        {body.map((item, index) => {
          return (
              <tr key={index}>
                <td>
                  <span className='flexRow flexSpaceEvenly'>
                    <FaTimes
                      className='red resizeWithOpacity pointer fontSize12'
                      onClick={(e) => {
                        if (setDeleteOption == null) return;
                        if (item.id == null) return;
                        setDeleteOption(item.id);
                      }}
                    />
                    <FaPen
                      className='resizeWithOpacity pointer'
                      onClick={(e) => {
                        if (setEditOption == null) return;
                        if (item.id == null) return;
                        setEditOption(item.id);
                      }}
                    />
                  </span>
                </td>
                {head.map((entry, index) => (
                  <td key={index}>
                    {Array.isArray(item[entry]) ? (
                      <span className='tooltipContainer textDecUnderLine'>
                        {item[entry].length} {entry}
                        <div className='tooltipText'>
                          {item[entry].map((item) => (
                            <p>{item}</p>
                          ))}
                        </div>
                      </span>
                    ) : (
                      <span>{item[entry]}</span>
                    )}
                  </td>
                ))}
              </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DisplayTable;
