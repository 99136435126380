import React from "react";

const InputNotificationPanel = (props) => {
  const { inputs, setEventInput, setInputState, inputState, eventInput } =
    props;

  return (
    <>
      <label className='marginToBoAuto maxHeight2rem'>Eingang</label>
      <select
        value={eventInput}
        className='maxHeight2rem marginToBoAuto'
        onChange={(e) => {
          setEventInput(e.target.value);
        }}
      >
        <option value='' selected disabled>
          Bitte wählen...
        </option>
        {inputs.map((item) => (
          <option value={item.remoteInputIndex}>{item.remoteInputName}</option>
        ))}
      </select>
      <label className='marginToBoAuto'>Status</label>
      <select
        value={inputState}
        className='maxHeight2rem marginToBoAuto'
        onChange={(e) => setInputState(e.target.value)}
      >
        <option disabled selected>
          Bitte wählen
        </option>
        <option value={true}>EIN</option>
        <option value={false}>AUS</option>
      </select>
    </>
  );
};

export default InputNotificationPanel;
