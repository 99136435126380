import React, { useEffect, useState } from "react";
import { FaTimes, FaRegAddressBook, FaPlus, FaCheck } from "react-icons/fa";
import { validateMail } from "../01_Helpers/checkEmailValid";

const AdressbookPanel = (props) => {
  const [selectMode, setSelectMode] = useState(false);
  const [adressBook, setAdressBook] = useState([]);
  const [manuMail, setManuMail] = useState("");
  const { adresses, setAdresses } = props;

  useEffect(() => {
    fetch(localStorage.getItem("location"), {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
        "Accept-Encoding": "gzip",
      },
    })
      .then((res) => {
        if (res.status !== 200) throw new Error("Error");
        return res.json();
      })
      .then((res) => {
        setAdressBook(JSON.parse(res.adressBook));
      })
      .catch((e) => console.error);
  }, []);

  return (
    <div>
      <div className='card'>
        {selectMode ? (
          <>
            <h5>Adressbuch</h5>
            <ul className='gray-800 gridCol1x1fr gap05 marginTop05'>
              {adressBook.map((item, index) => (
                <li>
                  <span>
                    {adresses.includes(item) ? (
                      <FaTimes
                        className='red resizeWithOpacity pointer'
                        onClick={(e) => {
                          let adressesWithoutThisItem = adresses.filter(
                            (thisItem) => thisItem !== item
                          );
                          setAdresses(adressesWithoutThisItem);
                        }}
                      />
                    ) : (
                      <FaTimes style={{ opacity: 0 }} />
                    )}
                  </span>
                  <span
                    className={
                      adresses.includes(item) ? "pointer orange-800" : "pointer"
                    }
                    key={index}
                    onClick={(e) => {
                      setAdresses([...adresses, item]);
                    }}
                  >
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <h5>Email senden an: </h5>
            <ul className='marginTop05'>
              {adresses.map((item, index) => (
                <li key={index}>
                  <span className=''>
                    <FaTimes
                      className='red resizeWithOpacity pointer'
                      onClick={(e) => {
                        let adressesWithoutThisItem = adresses.filter(
                          (thisItem) => thisItem !== item
                        );
                        setAdresses(adressesWithoutThisItem);
                      }}
                    />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className='marginTop10'>
        <h5 className='padding05'>Adressen hinzufügen:</h5>
        <span className='flexRow flexSpaceEvenly'>
          <input
            onKeyDown={(e) => {
              if (e.key !== "Enter") return;
              e.preventDefault();
              if (validateMail(manuMail) === false)
                return alert("Bitte prüfen Sie die eingegebene Email-Adresse");
              if(adresses.includes(manuMail))
                return alert("Adresse wurde bereits hinzugefügt.")
              setAdresses([...adresses, manuMail]);
              setManuMail("");
            }}
            value={manuMail}
            className={
              selectMode
                ? "maxWidth25ch marginToBoAuto center opacity00"
                : "maxWidth25ch marginToBoAuto center"
            }
            disabled={selectMode}
            onChange={(e) => setManuMail(e.target.value.trim())}
          ></input>
          <FaPlus
            className={
              selectMode
                ? "opacity00"
                : "resizeWithOpacity pointer fontSize15 marginToBoAuto"
            }
            onClick={() => {
              if (validateMail(manuMail) === false)
                return alert("Bitte prüfen Sie die eingegebene Email-Adresse");
              if(adresses.includes(manuMail))
                return alert("Adresse wurde bereits hinzugefügt.")
              setAdresses([...adresses, manuMail]);
              setManuMail("");
            }}
          />
          <hr orientation='vertical' className='maxHeight2rem marginToBoAuto' />
          {selectMode ? (
            <FaCheck
              className='resizeWithOpacity pointer fontSize15 marginToBoAuto green'
              onClick={(e) => {
                e.preventDefault();
                setSelectMode(!selectMode);
              }}
            />
          ) : (
            <FaRegAddressBook
              onClick={(e) => {
                e.preventDefault();
                setSelectMode(!selectMode);
              }}
              className='resizeWithOpacity pointer fontSize15 marginToBoAuto'
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default AdressbookPanel;
