import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RemoteOverviewWidget from "./RemoteOverviewWidget";
import "./RemoteOverView.css";

const RemoteOverview = (props) => {
  let scope = JSON.parse(localStorage.getItem("scope"));
  let navbar = JSON.parse(localStorage.getItem("navbar"));
  let [remoteOverviewContent, setRemoteOverviewContent] = useState([]);

  useEffect(() => {
    let content = scope.remote.map((item, index) => {
      let remoteID = item.remoteID;
      let updateLink = item.href;
      let navbarContext = navbar.remotes.find(
        (item) => item.remoteID === remoteID
      );
      let portalLink = navbarContext.to;
      let remoteName = navbarContext.name;

      return { remoteID, updateLink, portalLink, remoteName };
    });

    setRemoteOverviewContent(content);
  }, []);
  return (
    <div className=''>
      <h3>Remotes</h3>
      <div className='anlagenOverviewContainer gap05 marginTop10'>
        {remoteOverviewContent.map((item, index) => {
          return (
            <RemoteOverviewWidget
              key={index}
              remoteContext={item}
            ></RemoteOverviewWidget>
          );
        })}
      </div>
    </div>
  );
};

export default RemoteOverview;
