let iconv = require("iconv-lite");

exports.decodeDaten = (daten) => {
  let dataBuffer = new Buffer.from(daten, "hex");

  return {
    Datum: iconv.decode(
      dataBuffer.slice(0, 15).filter((item) => item !== 0x00),
      "latin-1"
    ),
    "Projekt-Nummer": iconv.decode(
      dataBuffer.slice(16, 31).filter((item) => item !== 0x00),
      "latin-1"
    ),
    Objekt: iconv.decode(
      dataBuffer.slice(32, 47).filter((item) => item !== 0x00),
      "latin-1"
    ),
    Betreiber: iconv.decode(
      dataBuffer.slice(48, 63).filter((item) => item !== 0x00),
      "latin-1"
    ),
    Errichter: iconv.decode(
      dataBuffer.slice(64, 79).filter((item) => item !== 0x00),
      "latin-1"
    ),
  };
};
