import React, { useState, useEffect } from "react";
import AddNewRemotesToUser from "./AddNewRemotesToUser";

const CreateNewModule = (props) => {
  const [send, setSend] = useState(false);
  const [remoteScope, setRemoteScope] = useState([]);

  useEffect(() => {
    console.log({ remoteScope });
  });
  return (
    <div className='card'>
      <h3>Neues Modul anlegen</h3>
      <div className='marginTop10'>
        <AddNewRemotesToUser
          remoteScope={remoteScope}
          setRemoteScope={setRemoteScope}
          isSent={() => {}}
          send={send}
        ></AddNewRemotesToUser>
      </div>
      <button
        onClick={() => {
          setSend(!send);
        }}
      >
        Speichern
      </button>
    </div>
  );
};

export default CreateNewModule;
