import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { getNickName } from "../01_Helpers/nameHelpers";
const RemoteIDFrame = (props) => {
  let { remoteID } = useParams();
  let nickName = getNickName(remoteID);

  return (
    <div className='remoteIDFrame'>
      <div className='remoteWidgetHeader'>
        <h3>{nickName}</h3>
      </div>
      <Outlet />
    </div>
  );
};

export default RemoteIDFrame;

//{nickName ? `${nickName}` : `${remoteID}`}
