import React from "react";
import "./RemoteWidget.css";
import { VscDebugDisconnect } from "react-icons/vsc";
import { HiOutlineCheckCircle } from "react-icons/hi";
function StatusRemoteWidget(props) {
  return (
    <div className='SubWidget card gridItemsCenter delay4 padding00'>
      <h4>Verbindung</h4>
      <div className='content'>
        {props.status ? (
          <HiOutlineCheckCircle className='statusWidget check' color='green' />
        ) : (
          <VscDebugDisconnect className='statusWidget error' color='red' />
        )}
      </div>
    </div>
  );
}

export default StatusRemoteWidget;
