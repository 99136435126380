import React from "react";
import { Link } from "react-router-dom";

const AdminLandingPage = (props) => {
  return (
    <div className='maxWidth300px '>
      <Link
        to='/admin/createuser'
        className='card gap10 padding10 bgColorOrangeOnHover marginTop10 bold gray-800'
      >
        <span className='fontSize12'>Nutzer anlegen</span>
      </Link>
      <Link
        to='/admin/edituser'
        className='card gap10 padding10 bgColorOrangeOnHover marginTop10 bold gray-800'
      >
        <span className='fontSize12'>Nutzer bearbeiten</span>
      </Link>
      <Link
        to='/admin/editremote'
        className='card gap10 padding10 bgColorOrangeOnHover marginTop10 bold gray-800'
      >
        <span className='fontSize12'>Remote bearbeiten</span>
      </Link>
    </div>
  );
};

export default AdminLandingPage;
