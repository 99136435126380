import React, { useState, useEffect } from "react";
import { logbuchHelper } from "../01_Helpers/anlagenLogbuchHelper";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

let AnlagenLogbuchWidget = (props) => {
  let [displayedLogEntries, setDisplayedLogEntries] = useState([]);
  let [maxPages, setMaxPages] = useState(1);
  let [logbuchPages, setLogbuchPages] = useState([]);
  let [activePage, setActivePage] = useState(null);
  let logbuch = props.logbuch;

  useEffect(() => {
    let logDecoded = [];
    let trimLogBuch = logbuch.sort((a, b) => b.unixtimestamp - a.unixtimestamp);
    if (props.limit) {
      trimLogBuch = trimLogBuch.slice(0, props.limit);
      logDecoded = trimLogBuch.map((item, index, array) =>
        logbuchHelper(item.logbuch)
      );
      return setDisplayedLogEntries(logDecoded);
    }
    let logbuchPages = [];

    for (let i = 0, lastIndex = 0; i < trimLogBuch.length / 20 + 1; i++) {
      let logBuchPage = [];
      for (let j = 0; j < 20; j++, lastIndex++) {
        logBuchPage.push(trimLogBuch[i + lastIndex]);
      }
      let filterNullVals = logBuchPage.filter((item) => item != null);
      if (filterNullVals.length === 0) continue;
      logbuchPages.push(filterNullVals);
    }
    setMaxPages(logbuchPages.length);
    setLogbuchPages(logbuchPages);
    if (activePage == null) setActivePage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.logbuch]);

  useEffect(() => {
    if (activePage == null) return;
    if (logbuchPages[activePage] == null) return;
    let logDecoded = logbuchPages[activePage].map((item) =>
      logbuchHelper(item.logbuch)
    );
    setDisplayedLogEntries(logDecoded);
  }, [activePage, logbuchPages]);

  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader flexRow flexSpaceBetween'>
        <h3>Logbuch</h3>
        {(props.limit === 0 || props.limit >= 20) && (
          <span className='flexRow gap05'>
            <FaArrowLeft
              className='marginLR10 fontSize12 marginToBoAuto resizeWithOpacity orange-1000 pointer'
              onClick={(e) => {
                let val = activePage;
                if (val === 0) return setActivePage(0);
                return setActivePage(val - 1);
              }}
            />
            <label className='marginToBoAuto'>Seite</label>
            <p className='marginToBoAuto bold fontSize08'>
              {activePage + 1} / {maxPages}
            </p>
            <FaArrowRight
              className='marginLR10 fontSize12 marginToBoAuto resizeWithOpacity orange-1000 pointer'
              onClick={(e) => {
                let val = activePage;
                if (val >= maxPages - 1) return setActivePage(maxPages - 1);
                return setActivePage(val + 1);
              }}
            />
          </span>
        )}
      </div>
      <div className='anlagenWidgetBody'>
        {displayedLogEntries.map((item, index) => (
          <div
            key={index}
            className='LogBuchEntry marginTop05 paddingLeft10 borderRadius10 marginTop10 bgColorOrangeOnHover'
          >
            <span className='LogbuchID gray-800 bold'>
              ID: {item.deviceID} - {item.typ}
            </span>
            <span className='LogBuchDate gray-800 bold'>{item.zeit.date}</span>
            <span className='LogBuchZeit gray-800 bold'>
              {item.zeit.time}Uhr
            </span>
            <span className='LogbuchContent'>{item.content}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnlagenLogbuchWidget;
