import React from "react";
import { FaTimes, FaCheck, FaTrashAlt, FaPencilAlt } from "react-icons/fa";

const ToolPanel = (props) => {
  let {
    saveChanges,
    discardChanges,
    editMode,
    setEditMode,
    deleteItem,
    noTrash,
  } = props;
  return (
    <div className='gridCol2x1fr marginTop10'>
      {editMode ? (
        <>
          <FaTimes
            className='fontSize20 marginLRAuto resizeWithOpacity pointer red'
            onClick={(e) => {
              discardChanges();
            }}
          />
          <FaCheck
            className='fontSize20 marginLRAuto resizeWithOpacity pointer green'
            onClick={(e) => {
              saveChanges("YEP");
            }}
          />
        </>
      ) : (
        <>
          {noTrash ? (
            <div></div>
          ) : (
            <FaTrashAlt
              className='fontSize15 marginLRAuto resizeWithOpacity pointer red'
              onClick={() => {
                deleteItem();
              }}
            />
          )}
          <FaPencilAlt
            className='fontSize15 marginLRAuto resizeWithOpacity pointer '
            onClick={(e) => {
              setEditMode(true);
            }}
          />
        </>
      )}
    </div>
  );
};

export default ToolPanel;
