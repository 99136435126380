import React, { useState, useEffect } from "react";
import logo from "./transThumMit.png";
import "./Login.css";
import { useNavigate, useLocation } from "react-router-dom";
import { baseurl } from "../01_Helpers/endpoints";

function LoginForm(props) {
  const { setRemotes, setNavbar } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (localStorage.getItem("token") == null) return;
  }, []);

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    let user = queryParams.get('user');
    let pass = queryParams.get('pwd');
    if(user != null) setEmail(user);
    if(pass != null) setPassword(pass);
  }, [location.search]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let auth = Buffer.from(`${email}:${password}`).toString("base64");
    let res = await fetch(`${baseurl}/login`, {
      method: "GET",
      headers: {
        Authorization: auth,
      },
    })
      .then(async (res) => {
        if (res.status !== 200) return false;
        let resJson = await res.json();
        return resJson;
      })
      .catch((err) => {
        return false;
      });
    if (!res) {
      setLoginError("Fehler bei der Anmeldung!");
      return;
    }
    localStorage.setItem("location", res.location);
    let user = await fetch(res.location, {
      method: "GET",
      headers: {
        Authorization: res.token,
      },
    })
      .then(async (res) => await res.json())
      .catch((err) => console.log(err));
    localStorage.setItem("navbar", JSON.stringify(user.navbar));
    localStorage.setItem("token", JSON.stringify(res.token));
    localStorage.setItem("scope", JSON.stringify(user.scope));
    setNavbar(user.navbar);
    setRemotes(user.scope.remote);
    props.setLoggedIn(res.token ? true : false);

    navigate("/dashboard");
  };

  return (
    <div className='LoginForm textAlignCenter'>
      <form
        className='card padding10 loginForm-form gap05'
        onSubmit={handleSubmit}
        method='get'
        autoComplete='off'
      >
        <img className='LoginLogo' src={''} alt='Logo'></img>
        <p className='loginErrorField' style={{ color: "orange" }}>
          {loginError}
        </p>
        <label htmlFor='uname'>Username</label>
        <input
          type='text'
          placeholder='Enter Username'
          name='uname'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor='pwd'>Password</label>
        <input
          type='password'
          placeholder='Enter Password'
          name='pwd'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type='submit'>Login</button>
      </form>
    </div>
  );
}

export default LoginForm;
