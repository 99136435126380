export async function refreshLocalStorage() {
  let location = localStorage.getItem("location");
  let token = localStorage.getItem("token");
  let user = await fetch(location, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  })
    .then(async (res) => await res.json())
    .then((res) => {
      localStorage.setItem("navbar", JSON.stringify(res.navbar));
      localStorage.setItem("scope", JSON.stringify(res.scope));
      window.dispatchEvent(new Event("storage"));
    })
    .catch((err) => console.log(err));
}
