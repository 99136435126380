import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { refreshLocalStorage } from "../01_Helpers/refreshLocalStorage";
import ToolPanel from "../02_Tools/ToolPanel";

const RemoteUserSettings = (props) => {
  const { remoteID } = useParams();
  const [remoteName, setRemoteName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [anlagenID, setAnlagenID] = useState("");
  const [swVersion, setSwVersion] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [newNick, setNewNick] = useState("");
  const [reload, setReload] = useState(false);
  let token = JSON.parse(localStorage.getItem("token"));
  let scope = JSON.parse(localStorage.getItem("scope"));
  let href = scope.remote.find((item) => item.remoteID === remoteID).href;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${href}?timeLimit=latest`, {
      signal: signal,
      method: "GET",
      headers: {
        Authorization: token,
        "accept-encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setRemoteName(res.remoteNickName.nickName);
        setNewNick(res.remoteNickName.nickName);
        setSerialNumber(res.remoteSerialNumber.serialNumber);
        setAnlagenID(res.scope.anlagen[0].anlagenID);
        setSwVersion(res.remoteSWInfo.swVersion);
        setIsLoading(false);
      });
  }, [reload]);

  if (isLoading)
    return (
      <div className='card maxWidth500px marginTop10'>
        <ScaleLoader color='rgba(255, 166, 0, 0.6)' />
      </div>
    );
  return (
    <div className='card maxWidth500px marginTop10'>
      <h3>Remote Daten</h3>
      <div className='marginTop10 paddingLeft10 gridCol15ch1fr gap05 maxHeightMinContent'>
        <p className='gray-800  bold textAlignStart marginToBoAuto'>
          Remote-ID:
        </p>
        <p className='textAlignStart'>{remoteID}</p>
        <p className='gray-800  bold textAlignStart marginToBoAuto'>Name:</p>
        {editMode ? (
          <input
            value={newNick}
            onChange={(e) => setNewNick(e.target.value)}
          ></input>
        ) : (
          <p className='textAlignStart'>{remoteName}</p>
        )}
        <p className='gray-800  bold textAlignStart marginToBoAuto'>
          Seriennummer:
        </p>
        <p className='textAlignStart'>{serialNumber}</p>
        <p className='gray-800  bold textAlignStart marginToBoAuto'>
          Anlagen-ID:
        </p>
        <p className='textAlignStart'>{anlagenID}</p>
        <p className='gray-800  bold textAlignStart marginToBoAuto'>
          SW-Version:
        </p>
        <p className='textAlignStart'>{swVersion}</p>
      </div>
      <span className='marginTop10'>
        <hr orientation='horizontal' />
      </span>
      <ToolPanel
        noTrash={true}
        editMode={editMode}
        setEditMode={setEditMode}
        discardChanges={() => {
          setNewNick(remoteName);
          setEditMode(false);
        }}
        saveChanges={() => {
          setIsLoading(true);
          setEditMode(false);
          fetch(href, {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              remoteNickName: {
                nickName: newNick,
              },
            }),
          }).then((res) => {
            setReload(!reload);
            refreshLocalStorage();
          });
        }}
      />
    </div>
  );
};

export default RemoteUserSettings;
