import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Searchbar from "../02_Tools/SearchBar";

const setUnactiveByClassName = (className) => {
  let selectedCollection = document.getElementsByClassName(className);
  for (let element of selectedCollection) {
    element.classList.remove("active");
  }
};

const RightNav = (props) => {
  let { navbar, submenu, setRoute, rightMenuHeader, refresh } = props;
  let [links, setLinks] = useState([]);
  let location = useLocation();

  useEffect(() => {
    if (submenu == null) return;
    console.log("navbarchgesdgfsdf");
    setLinks(navbar[submenu]);
  }, [navbar, submenu, location, refresh]);

  if (
    navbar == null ||
    submenu == null ||
    navbar[submenu] == null ||
    links == null
  ) {
    return <></>;
  }

  return (
    <nav className='sidenav rightnav'>
      <div className='LinkContainer'>
        <div className='topSection'>
          <h5>{rightMenuHeader}</h5>
          <hr orientation='horizontal'></hr>
          {submenu != null && navbar != null && (
            <Searchbar
              arrayToFilter={navbar[submenu]}
              setOutput={setLinks}
              keysToFilterBy={Object.keys(navbar[submenu][0])}
            ></Searchbar>
          )}
          <div className='marginTop05'>
            {links
              .filter((item) => item !== false)
              .map((item, index) => {
                return (
                  <Link
                    className={
                      location.pathname.startsWith(item.to)
                        ? "sidenavLink rightNavLink active"
                        : "sidenavLink rightNavLink"
                    }
                    to={item.to}
                    key={index}
                    onClick={(e) => {
                      setRoute(item.to);
                      setUnactiveByClassName("rightNavLink");
                    }}
                  >
                    {item.name}
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default RightNav;
