import React from "react";

const RemoteList = (props) => {
  const { remoteList, setActiveRemote, activeRemote } = props;

  if (remoteList.length === 0) return <></>;

  return (
    <>
      <ul className='textAlignStart marginTop05 gray-600'>
        {remoteList.map((item, index) => {
          return (
            <li
              key={index}
              className={
                item.remoteID === activeRemote
                  ? "resizeOnHover11 pointer orange-800"
                  : "resizeOnHover11 pointer "
              }
              onClick={() => setActiveRemote(item.remoteID)}
            >
              {item.nickName.length != 0 ? item.nickName : item.remoteID}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default RemoteList;
