const getType = (logbyte2) => {
  let types = [
    "Control Unit",
    "Amp Unit",
    "Rel Unit",
    "Power Unit",
    "MTP FW",
    "MTP 1",
    "MTP 200",
    "MTP 10",
    "PPC",
    "MTP Z",
    "a/b",
    "FES",
    "MTP U (NET)",
    "MTP U (MTP)",
  ];
  return types[logbyte2];
};

const getLogType = (logbyte0) => {
  const logTypes = [
    "Keine Antwort",
    "Signal ein/aus",
    "EOL Fehler",
    "Impedanzfehler",
    "EV Fehler",
    "Havarieumschaltung",
    "NF Fehler",
    "Notstrom",
    "Eingang",
    "Sendefehler",
    "MP3 Modul",
    "Systemfehler",
    "Notfallmikro",
    "Login",
    "Logout",
    "Empfang neuer Systemdaten",
    "Abschaltung",
    "Reset",
    "FES",
    "MTP NET",
  ];
  return logTypes[logbyte0 - 1];
};

const getFehlerArt = (logbyte5) => {
  const fehlerArten = [
    "kurz",
    "offen",
    "erd",
    "Sicherung",
    "Akkubetrieb",
    "Ladeteil",
    "Akku",
    "Innenwiderstand",
  ];
  return fehlerArten[logbyte5 - 1];
};

const getBeschreibung = (logbyte8) => {
  if (logbyte8 === 0 || logbyte8 >= 32) return "";
  const beschreibungen = [
    "Linie", //1
    "Gruppe", //2
    "Sammelruf", //3
    "Endverstärker", //4
    "Kanal", //5
    "MP3", //6
    "MTP FW", //7
    "Ausgang", //8
    "Ladeteil", //9
    "Batterie", //10
    "Eingangsnummer", //11
    "SD-Card", //12
    "Sendefehler", //13
    "Speicher", //14
    "Mikrofonkapsel", //15
    "Software", //16
    "Audio", //17
    "BMZ E 1/2", //18
    "Störungsausgang", //19
    "Steuereingänge", //20
    "LS Linien A", //21
    "LS Linien B", //22
    "Steuerrelais", //23
    "Linienrelais", //24
    "Verstärker", //25
    "Notstrom", //26
    "MTP-Bus 1", //27
    "MTP-Bus 2", //28
    "FES Bedienteil", //29
    "seriell FES", //30
    "Eingangsfehler", //31
    "seriell USB", //32
  ];
  return beschreibungen[logbyte8 - 1];
};

const pleaseMakeThisASentence = (sentenceStuff) => {
  let start = `${sentenceStuff.logType}`;
  if (sentenceStuff.logType === "Keine Antwort")
    return `${start} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Signal ein/aus")
    return `Signal: ${sentenceStuff.signalNummer}, von Makro:${sentenceStuff.makroNummer}, Prio:${sentenceStuff.prio} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "EOL Fehler")
    return `${start} ${sentenceStuff.beschreibung} ${sentenceStuff.linienIndex} ${sentenceStuff.nummer} ${sentenceStuff.fehlerArt}  - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Impedanzfehler")
    return `${start} ${sentenceStuff.beschreibung} ${sentenceStuff.linienIndex} ${sentenceStuff.nummer} - ${sentenceStuff.onOff} `;
  if (sentenceStuff.logType === "EV Fehler")
    return `${start} ID: ${sentenceStuff.deviceID}  - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Havarieumschaltung")
    return `${start} EV ${sentenceStuff.nummer} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "NF Fehler")
    return `${start} ${sentenceStuff.beschreibung} ${sentenceStuff.nummer} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Notstrom")
    return sentenceStuff.fehlerArt === "Sicherung"
      ? `${start} ${sentenceStuff.fehlerArt} ${sentenceStuff.beschreibung} ${sentenceStuff.nummer}  - ${sentenceStuff.onOff}`
      : `${start} ${sentenceStuff.fehlerArt}  - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Eingang")
    return `${start} ${sentenceStuff.nummer}  ${sentenceStuff.fehlerArt}  - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Sendefehler")
    return sentenceStuff.beschreibung !== "Sammelruf"
      ? `${start} ${sentenceStuff.beschreibung} ${sentenceStuff.nummer}  - ${sentenceStuff.onOff}`
      : `${start} ${sentenceStuff.beschreibung}  - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "MP3 Modul")
    return `${start} ${sentenceStuff.beschreibung}  - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Systemfehler")
    return `${start} ${sentenceStuff.beschreibung} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Notfallmikro")
    return `${start} ${sentenceStuff.beschreibung} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Login")
    return `${start} ${sentenceStuff.beschreibung} Zugangsebene ${sentenceStuff.logbuchBuffer[9]}`;
  if (sentenceStuff.logType === "Logout")
    return `${start} ${sentenceStuff.beschreibung}`;
  if (sentenceStuff.logType === "Empfang neuer Systemdaten") return `${start}`;
  if (sentenceStuff.logType === "Abschaltung")
    return `${start} ${sentenceStuff.beschreibung} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "Reset") return `${start}`;
  if (sentenceStuff.logType === "FES")
    return `${start} ${sentenceStuff.deviceID} ${sentenceStuff.beschreibung} - ${sentenceStuff.onOff}`;
  if (sentenceStuff.logType === "MTP NET")
    return `${start} ${sentenceStuff.deviceID} ${sentenceStuff.beschreibung} - ${sentenceStuff.onOff}`;
};

const makeThisANiceTime = (h, min, day, mon, year) => {
  let time = new Date(2000 + year, mon - 1, day, h, min);
  return {
    date: time.toLocaleDateString("de-DE"),
    time: time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  };
};

exports.logbuchHelper = (logbuchEntry) => {
  let logbuchBuffer = new Buffer.from(logbuchEntry, "hex");

  let logType = getLogType(logbuchBuffer[0]);
  let deviceID = logbuchBuffer[1];
  let typ = getType(logbuchBuffer[2]);
  let prio = logbuchBuffer[3];
  let signalNummer = logbuchBuffer[4];
  let fehlerArt = getFehlerArt(logbuchBuffer[5]);
  let makroNummer = logbuchBuffer[6];
  let linienIndex = logbuchBuffer[7] === 0 ? "A" : "B";
  let beschreibung = getBeschreibung(logbuchBuffer[8]);
  let nummer = logbuchBuffer[9] + 1;
  let onOff = logbuchBuffer[10] ? "EIN" : "AUS";
  let zeit = `${logbuchBuffer[12]}:${logbuchBuffer[11]} - ${logbuchBuffer[13]}.${logbuchBuffer[14]}.${logbuchBuffer[15]}`;
  zeit = makeThisANiceTime(
    logbuchBuffer[12],
    logbuchBuffer[11],
    logbuchBuffer[13],
    logbuchBuffer[14],
    logbuchBuffer[15]
  );
  return {
    content: pleaseMakeThisASentence({
      logType,
      deviceID,
      typ,
      prio,
      signalNummer,
      fehlerArt,
      makroNummer,
      linienIndex,
      beschreibung,
      nummer,
      onOff,
      zeit,
      logbuchBuffer,
    }),
    zeit,
    deviceID,
    typ,
  };
};
