exports.getNickName = (remoteID) => {
  let navbar = JSON.parse(localStorage.getItem("navbar"));
  return navbar.remotes.reduce(
    (prev, item) => (item.remoteID === remoteID ? item.name : prev),
    remoteID
  );
};

exports.getAnlagenName = (anlagenID) => {
  let navbar = JSON.parse(localStorage.getItem("navbar"));
  return navbar.anlagen.reduce(
    (prev, item) => (item.anlagenID === anlagenID ? item.name : prev),
    anlagenID
  );
};
