import React, { useState, useEffect } from "react";

const TempNotificationPanel = (props) => {
  const [tempOverOrUnder, setTempOverOrUnder] = useState(null);
  const [temp, setTemp] = useState(null);

  const {
    tempOver,
    tempUnder,
    setTempOver,
    setTempUnder,
    eventInput,
    setEventInput,
  } = props;

  useEffect(() => {
    if (tempOverOrUnder == null) return;
    if (temp == null) return;

    if (tempOverOrUnder === "tempOver") {
      setTempOver(temp);
      return setTempUnder(null);
    }

    if (tempOverOrUnder === "tempUnder") {
      setTempOver(null);
      return setTempUnder(temp);
    }
  }, [tempOverOrUnder, temp]);

  useEffect(() => {
    if(tempOver !== null){
      setTempOverOrUnder('tempOver')
      setTemp(tempOver)
      setTempUnder(null)
    }
    if(tempUnder !== null){
      setTempOverOrUnder('tempUnder')
      setTemp(tempUnder)
      setTempOver(null)
    }
  },[tempOver, tempUnder])

  return (
    <>
      <label className='marginToBoAuto'>Bedingung</label>
      <select
        value={tempOverOrUnder}
        onChange={(e) => {
          setTempOverOrUnder(e.target.value);
        }}
      >
        <option value='' selected disabled>
          Bitte wählen...
        </option>
        <option value='tempOver'>Temperatur über</option>
        <option value='tempUnder'>Temperatur unter</option>
      </select>
      <label className='marginToBoAuto'>Sensor</label>
      <select
        value={eventInput}
        onChange={(e) => setEventInput(e.target.value)}
      >
        <option value='' disabled selected>
          Bitte wählen...
        </option>
        <option value='intern'>intern</option>
        <option value='extern'>extern</option>
      </select>
      <label className='marginToBoAuto'>Temperatur</label>
      <span className='gridCol2x1fr'>
        <input
          type='number'
          value={temp}
          onChange={(e) => {
            let temp = parseFloat(e.target.value)
            if(isNaN(temp) && e.target.value !== null) return setTemp(null)
            if (e.target.value > 80) return setTemp(80);
            if (e.target.value < -40) return setTemp(-40);
            setTemp(e.target.value);
          }}
        ></input>
        <label className='marginToBoAuto marginLeft05'>°C</label>
      </span>
    </>
  );
};

export default TempNotificationPanel;
