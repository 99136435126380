import React from "react";
import { Link } from "react-router-dom";

const UserLandingPage = (props) => {
  return (
    <div className='maxWidth400px '>
      <Link
        to='/settings/user/user'
        className='card gap10 padding10 bgColorOrangeOnHover marginTop10 bold gray-800'
      >
        <span className='fontSize12'>Benutzereinstellungen</span>
      </Link>
      <Link
        to='/settings/user/groups'
        className='card gap10 padding10 bgColorOrangeOnHover marginTop10 bold gray-800'
      >
        <span className='fontSize12'>Adressbuch</span>
      </Link>
    </div>
  );
};

export default UserLandingPage;
