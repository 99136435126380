import React from "react";
import { decodeLSLinien } from "../01_Helpers/anlagenLSLinienHelper";
import "./AnlagenDash.css";

const Lautsprecherlinen = (props) => {
  //Props lslinien.data + linienIndex
  let linienIndex = props.linienIndex;
  let lsLinien = props.lsLinien;
  let decodedLSLinien = decodeLSLinien(linienIndex, lsLinien);

  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Lautsprecherlinien {linienIndex}</h3>
      </div>
      <div className='anlagenWidgetBody'>
        <div className='lsFrame'>
          <div className='lsHeader gap10 bold'>
            <span>Linie</span>
            <span className='text-align-start'>Status</span>
          </div>
        </div>
        {decodedLSLinien.map((item, index, array) => {
          return (
            <div
              key={index}
              className='lsEntry bgColorOrangeOnHover paddingLeft05 gray-800 borderRadius10 lineHeight20 gap10'
            >
              <span>{item.linienNummer}</span>
              <span className='text-align-start'>{item.linienStatus}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Lautsprecherlinen;
