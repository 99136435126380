exports.decodeLSLinien = (index, data) => {
  let linienBuffer = new Buffer.from(data, "hex");
  let lsLinien = [];
  const getLinienStatus = (dataByte) => {
    let statusString = "";
    if (dataByte & 0x1) statusString += "aktiv, ";
    if (dataByte & 0x2) statusString += "offen, ";
    if (dataByte & 0x4) statusString += "Kurzschluss, ";
    if (dataByte & 0x8) statusString += "Erdschluss, ";
    if (dataByte & 0x10) statusString += "Impedanzabweichung, ";
    return statusString.substring(0, statusString.length - 2);
  };
  for (let i = 0; i < linienBuffer.length; i++) {
    if (linienBuffer[i] === 0) continue;
    lsLinien.push({
      linienIndex: index,
      linienNummer: i + 1,
      linienStatus: getLinienStatus(linienBuffer[i]),
    });
  }
  return lsLinien;
};
