import React from "react";
import { decodeEV } from "../01_Helpers/anlagenAmpHelper";
import { decodeLSLinien } from "../01_Helpers/anlagenLSLinienHelper";
import "./AnlagenDash.css";

const Amps = (props) => {
  //Props lslinien.data + linienIndex
  let amps = props.amps;
  let decodedAmps = decodeEV(amps);

  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Endverstärker</h3>
      </div>
      <div className='anlagenWidgetBody'>
        <div className='lsFrame'>
          <div className='lsHeader bold'>
            <span>Endverstärker</span>
            <span>Status</span>
          </div>
        </div>
        {decodedAmps.map((item, index, array) => {
          return (
            <div key={index} className='lsEntry'>
              <span>{item.ampID}</span>
              <span>{item.ampErrors}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Amps;
