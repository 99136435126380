import React from "react";
import { useParams } from "react-router-dom";
import RemoteWidget from "../DashboardWidgets/RemoteWidget";

const RemoteSingleOverview = (props) => {
  let { remoteID } = useParams();
  let token = JSON.parse(localStorage.getItem("token"));
  let scope = JSON.parse(localStorage.getItem("scope"));

  let href = scope.remote.find((item) => item.remoteID === remoteID).href;

  return (
    <div className='padding10'>
      <RemoteWidget
        display='card'
        key={remoteID}
        remoteID={remoteID}
        href={href}
        token={token}
      />
    </div>
  );
};

export default RemoteSingleOverview;
