import React from "react";
import { decodeEingaenge } from "../01_Helpers/anlagenEingaengeHelper";
import "./AnlagenDash.css";

const Eingaenge = (props) => {
  //Props lslinien.data + linienIndex
  let eingaenge = props.eingaenge;
  let decodedEingaenge = decodeEingaenge(eingaenge);

  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Eingänge</h3>
      </div>
      <div className='anlagenWidgetBody'>
        <div className='eingangFrame'>
          <div className='eingHeader bold gap10'>
            <span>Eingang</span>
            <span>Status</span>
          </div>
        </div>
        {decodedEingaenge.map((item, index, array) => {
          return (
            <div
              key={index}
              className='eingEntry gap10 bgColorOrangeOnHover gray-800 borderRadius10 lineHeight20'
            >
              <span>{item.eingangNummer}</span>
              <span>{item.eingangStatus}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Eingaenge;
