import React from "react";
import { Link } from "react-router-dom";

const NotificationLandingPage = (props) => {
  return (
    <div className='maxWidth400px '>
      <Link
        to='/settings/notifications/groups'
        className='card gap10 padding10 bgColorOrangeOnHover marginTop10 bold gray-800'
      >
        <span className='fontSize12'>Adressbuch</span>
      </Link>
      
    </div>
  );
};

export default NotificationLandingPage;
