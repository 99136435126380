import React, { useEffect, useState } from "react";
import AnlagenOverviewWidget from "./AnlagenOverviewWidget";
import "./AnlagenOverview.css";

let AnlagenOverview = (props) => {
  let scope = JSON.parse(localStorage.getItem("scope"));
  let navbar = JSON.parse(localStorage.getItem("navbar"));
  let [anlagenOverviewContent, setAnlagenOverviewContent] = useState([]);

  useEffect(() => {
    let content = scope.anlagen
      .map((item, index) => {
        let anlagenID = item.anlagenID;
        let updateLink = item.href;
        let navbarContext = navbar.anlagen.find(
          (item) => item.anlagenID === anlagenID
        );
        if (!navbarContext?.to) return false;
        let portalLink = navbarContext.to;
        let anlagenName = navbarContext.name;

        return { anlagenID, updateLink, portalLink, anlagenName };
      })
      .filter((item) => item !== false);
    setAnlagenOverviewContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=''>
      <h3>Anlagen</h3>
      <div className='anlagenOverviewContainer gap05 marginTop10'>
        {anlagenOverviewContent.map((item, index) => {
          return <AnlagenOverviewWidget anlagenContext={item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default AnlagenOverview;
