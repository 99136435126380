import React from "react";
import { Outlet } from "react-router-dom";

const AdminFrame = (props) => {
  return (
    <div className='frame padding10'>
      <div className='displayBlock'>
        <h3>Admin</h3>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminFrame;
