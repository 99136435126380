exports.anlagenEndpoints = [
  {
    name: "Status",
    to: "",
    route: "/settings/anlagen",
  },
  {
    name: "Störungen",
    to: "stoerungen",
    route: "/settings/anlagen",
  },
  {
    name: "Logbuch",
    to: "logbuch",
    route: "/settings/anlagen",
  },
  {
    name: "Power Unit",
    to: "powerunit",
    route: "/settings/anlagen",
  },
  {
    name: "Impedanzen",
    to: "impedanzen",
    route: "/settings/anlagen",
  },
  {
    name: "Lautsprecherlinien",
    to: "lslinien",
    route: "/settings/anlagen",
  },
  {
    name: "Eingänge",
    to: "eingaenge",
    route: "/settings/anlagen",
  },
  {
    name: "Endverstärker",
    to: "amps",
    route: "/settings/anlagen",
  },
  {
    name: "Schaltzeiten",
    to: "schaltzeiten",
    route: "/settings/anlagen",
  },
];

exports.remoteEndpoints = [
  {
    name: "Status",
    to: "",
    route: "/settings/modules",
  },
  {
    name: "Eingänge",
    to: "inputs",
    route: "/settings/modules",
  },
  {
    name: "Ausgänge",
    to: "outputs",
    route: "/settings/modules",
  },
  {
    name: "Temperatur",
    to: "temperatur",
    route: "/settings/modules",
  },
];

exports.permaLinks = [
  {
    name: "Dashboard",
    to: "/dashboard",
  },
];

exports.generateSpecialContent = (typeOfContent, header, nickName = null) => {
  let endpoints;
  switch (typeOfContent) {
    case "anlage":
      endpoints = this.anlagenEndpoints;
      break;
    case "remote":
      endpoints = this.remoteEndpoints;
      break;
    default:
      return;
  }
  return {
    header: nickName == null ? header : nickName,
    links: endpoints.map((item, index) => {
      return {
        name: item.name,
        to: `${item.route}/${header}/${item.to}`,
      };
    }),
  };
};

exports.baseurl = "https://api.remote-system.de";
