import React, { useEffect, useState } from "react";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { ScaleLoader } from "react-spinners";

const UserSettings = (props) => {
  let [userName, setUserName] = useState("");
  let [accountType, SetAccountType] = useState("");
  let [editMode, setEditMode] = useState(false);
  let [changePWD, setChangePWD] = useState(false);
  let [oldPWD, setOldPWD] = useState("");
  let [newPWD, setNewPWD] = useState("");
  let [repNewPWD, setRepNewPWD] = useState("");
  let [newUserName, setNewUsername] = useState("");
  let [isLoaded, setIsLoaded] = useState(false);
  let location = localStorage.getItem("location");
  let token = localStorage.getItem("token");
  useEffect(() => {
    fetch(location, {
      method: "GET",
      headers: {
        Authorization: token,
        "Accept-Encoding": "gzip",
        Accept: "*/*",
      },
    })
      .then((res) => {
        if (res.status !== 200)
          throw new Error("Problem! " + res.status + res.text());
        return res.json();
      })
      .then((res) => {
        setUserName(res.email);
        SetAccountType(res.accType);
        setIsLoaded(true);
      });
  }, [location, token]);

  return (
    <div className='card padding10 marginTop10 maxWidth400px'>
      <h3>Benutzereinstellungen</h3>
      {isLoaded ? (
        <div className='marginTop10'>
          {editMode ? (
            <>
              <h5 className='textAlignStart '>Username/Passwort ändern</h5>
              <div className='marginTop10 paddingLeft10 gridCol2x1fr gap05 maxHeightMinContent'>
                <label className='marginToBoAuto'>Username</label>
                <input
                  type='text'
                  placeholder=''
                  value={userName}
                  onChange={(e) => setNewUsername(e.target.value)}
                ></input>
                {changePWD ? (
                  <>
                    <label className='marginToBoAuto'>Altes Passwort</label>
                    <input
                      type='password'
                      placeholder=''
                      value={oldPWD}
                      onChange={(e) => setOldPWD(e.target.value)}
                    ></input>
                    <label className='marginToBoAuto'>Neues Passwort</label>
                    <input
                      type='password'
                      placeholder=''
                      value={newPWD}
                      onChange={(e) => setNewPWD(e.target.value)}
                    ></input>
                    <label className='marginToBoAuto'>
                      Neues Passwort (wdh.)
                    </label>
                    <input
                      type='password'
                      placeholder=''
                      value={repNewPWD}
                      onChange={(e) => setRepNewPWD(e.target.value)}
                    ></input>
                  </>
                ) : (
                  <>
                    <label className='marginToBoAuto'>Passwort</label>
                    <p
                      className='pointer textUnderline resizeWithOpacity marginToBoAuto orange-800'
                      onClick={() => setChangePWD(true)}
                    >
                      ändern
                    </p>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <h5 className='textAlignStart '>Übersicht</h5>
              <div className='marginTop10 paddingLeft10 gridCol2x1fr gap05 maxHeightMinContent'>
                <p className='gray-800  bold textAlignStart'>Username:</p>
                <p className='textAlignStart'>{userName}</p>
                <p className='gray-800  bold textAlignStart'>Passwort:</p>
                <p className='textAlignStart'>*****</p>
                <p className='gray-800  bold textAlignStart'>Account:</p>
                <p className='textAlignStart'>{accountType}</p>
              </div>
            </>
          )}
          <span className='marginTop10'>
            <hr orientation='horizontal' />
          </span>
          {editMode ? (
            <div className='gridCol2x1fr marginTop10'>
              <button
                onClick={() => {
                  setEditMode(false);
                  setChangePWD(false);
                }}
              >
                Abbrechen
              </button>
              <button
                onClick={() => {
                  let tblUsers =
                    changePWD === true
                      ? {
                          email: newUserName === "" ? userName : newUserName,
                          pwd: oldPWD,
                          newPwd: newPWD,
                        }
                      : {
                          email: newUserName === "" ? userName : newUserName,
                        };
                  let requestBody = { tblUsers };
                  fetch(location, {
                    method: "PATCH",
                    headers: {
                      Authorization: token,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestBody),
                  })
                    .then((res) => {
                      if (res.status !== 200) throw new Error("Problem");
                      return res.text();
                    })
                    .catch((e) => {
                      console.error(e);
                    });
                }}
              >
                Speichern
              </button>
            </div>
          ) : (
            <div className='gridCol2x1fr marginTop10'>
              <FaTrashAlt className='fontSize15 marginLRAuto resizeWithOpacity pointer red' />
              <FaPencilAlt
                className='fontSize15 marginLRAuto resizeWithOpacity pointer '
                onClick={() =>
                  userName === "Testzugang"
                    ? setEditMode(false)
                    : setEditMode(true)
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className='padding20 marginTop10'>
          <ScaleLoader color='rgba(255, 166, 0, 0.6)' className='marginTop10' />
        </div>
      )}
    </div>
  );
};

export default UserSettings;
