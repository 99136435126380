import React from "react";
import { FaTimes } from "react-icons/fa";
import { v4 as uuid } from "uuid";
const RemoteUserList = (props) => {
  const { userList, activeRemote, editMode, setPatchBody, patchBody } = props;

  if (activeRemote == null || activeRemote.length === 0) return <></>;

  return (
    <>
      <h5 className='textAlignStart'>Benutzer</h5>
      <ul className='textAlignStart marginTop05 gray-600 paddingLeft10'>
        {userList
          .filter((user) => user.remotes.includes(activeRemote))
          .map((remoteUser, index) => {
            return editMode ? (
              <li key={index} className='flexRow gap05'>
                <span>
                  <FaTimes
                    className='red resizeWithOpacity marginToBoAuto pointer'
                    visibility={
                      Object.keys(remoteUser).includes("deleteID")
                        ? "hidden"
                        : "visible"
                    }
                    onClick={(e) => {
                      let id = uuid();
                      setPatchBody([
                        ...patchBody,
                        {
                          id: id,
                          method: "DELETE",
                          database: "userDB",
                          table: "UserRemote",
                          selectors: {
                            userID: remoteUser.userID,
                            remoteID: activeRemote,
                          },
                        },
                      ]);
                      remoteUser.deleteID = id;
                    }}
                  />
                </span>
                <span
                  className={remoteUser.deleteID ? "red" : "marginToBoAuto"}
                >
                  {remoteUser.email}
                </span>
              </li>
            ) : (
              <li key={index}>{remoteUser.email}</li>
            );
          })}
      </ul>
    </>
  );
};

export default RemoteUserList;
