import React from "react";

const UserOverView = (props) => {
  const {
    activeUser,
    editMode,
    editMail,
    setEditMail,
    editAccType,
    setEditAccType,
  } = props;
  if (!activeUser) return <label>Bitte Nutzer wählen</label>;

  return (
    <div className='marginTop10 minWidth300px'>
      <h5 className='textAlignStart '>Übersicht</h5>
      <div className='marginTop10 paddingLeft10 gridCol10ch1fr gap05 maxHeightMinContent'>
        <label className='gray-800  bold textAlignStart'>Email:</label>
        {editMode ? (
          <input
            value={editMail}
            onChange={(e) => setEditMail(e.target.value)}
          ></input>
        ) : (
          <p className='textAlignStart'>{activeUser.email}</p>
        )}
        <label className='gray-800  bold textAlignStart'>Passwort:</label>
        {editMode ? (
          <p>Zurücksetzen</p>
        ) : (
          <p className='textAlignStart'>******</p>
        )}
        <label className='gray-800  bold textAlignStart'>Rolle:</label>
        {editMode ? (
          <select
            value={editAccType}
            onChange={(e) => setEditAccType(e.target.value)}
          >
            <option value='' disabled>
              Bitte wählen...
            </option>
            <option value='Betreiber'>Betreiber</option>
            <option value='Benutzer'>Benutzer</option>
          </select>
        ) : (
          <p className='textAlignStart'>{activeUser.accType}</p>
        )}
      </div>
    </div>
  );
};

export default UserOverView;
