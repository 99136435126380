import React, { useState, useEffect, useRef } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

const availableActions = ["aussschalten", "einsschalten"];

const availableDependencies = [
  "Temperatur",
  "Eingang",
  //   'Zeit'
];

const availableTempSensors = ["intern", "extern"];
const availableTempCondition = ["Temperatur über", "Temperatur unter"];

const availableInputStates = ["HIGH", "LOW"];

const AddOutputAction = (props) => {
  const {
    inputConfig,
    setAddOutputAction,
    setOutputActions,
    outputActions,
    outputIndex,
  } = props;
  let [turnOnOff, setTurnOnOff] = useState("setHigh");
  let [chosenDep, setChosenDep] = useState(0);
  let [prio, setPrio] = useState(5);
  let [duration, setDuration] = useState(null);
  // TempSensorStates
  let [tempSensor, setTempSensor] = useState("tempInternal");
  let [isOverOrUnder, setIsOverOrUnder] = useState("isOver");
  let [tempLimit, setTempLimit] = useState(25);

  let tempAction = {
    id: uuid(),
    action: turnOnOff,
    condition: {
      temp: {
        sensor: tempSensor,
        [isOverOrUnder]: tempLimit,
      },
    },
    duration: null,
    prio: prio,
  };
  //InputStates
  let [inputIndex, setInputIndex] = useState(0);
  let [inputState, setInputState] = useState("HIGH");

  let inputAction = {
    id: uuid(),
    action: turnOnOff,
    condition: {
      input: {
        inputIndex: inputIndex,
        state: inputState,
      },
    },
    duration: null,
    prio: prio,
  };
  return (
    <div className='widget add-output-action card'>
      <h3>Aktion hinzufügen - Ausgang {outputIndex}</h3>
      <h5>Aktion</h5>
      <select
        onChange={(e) => {
          if (e.target.selectedIndex === 0) setTurnOnOff("setHigh");
          if (e.target.selectedIndex === 1) setTurnOnOff("setLow");
        }}
      >
        <option>einschalten</option>
        <option>ausschalten</option>
      </select>
      <h5>Abhängigkeit</h5>
      <select
        onChange={(e) => {
          setChosenDep(e.target.selectedIndex);
        }}
      >
        {availableDependencies.map((item, index) => {
          return <option selected={index === chosenDep}>{item}</option>;
        })}
      </select>
      {chosenDep === 0 && (
        <>
          <h5>Sensor</h5>
          <select
            onChange={(e) => {
              if (e.target.selectedIndex === 0) setTempSensor("tempInternal");
              if (e.target.selectedIndex === 1) setTempSensor("tempExternal");
            }}
          >
            {availableTempSensors.map((item, index) => {
              return <option>{item}</option>;
            })}
          </select>
          <h5>Bedingung</h5>
          <select
            onChange={(e) => {
              if (e.target.selectedIndex === 0) {
                setIsOverOrUnder("isOver");
              }
              if (e.target.selectedIndex === 1) {
                setIsOverOrUnder("isUnder");
              }
            }}
          >
            {availableTempCondition.map((item, index) => {
              return <option>{item}</option>;
            })}
          </select>
          <h5>Wert (in °C)</h5>
          <span>
            <input
              type='number'
              defaultValue={25}
              onChange={(e) => {
                setTempLimit(e.target.value);
              }}
            ></input>
          </span>
          <h5>Priorität</h5>
          <span>
            <input
              type='number'
              defaultValue={5}
              min={0}
              max={10}
              onChange={(e) => {
                if (e.target.value > 10) e.target.value = 10;
                if (e.target.value < 0) e.target.value = 0;
                setPrio(e.target.value);
              }}
            ></input>
          </span>
        </>
      )}
      {chosenDep === 1 && (
        <>
          <h5>Eingang</h5>
          <select
            onChange={(e) => {
              setInputIndex(e.target.selectedIndex);
            }}
          >
            {props.inputConfig.map((item, index) => {
              return <option>{item.remoteInputName}</option>;
            })}
          </select>
          <h5>Status</h5>
          <select
            onChange={(e) => {
              setInputState(availableInputStates[e.target.selectedIndex]);
            }}
          >
            {availableInputStates.map((item, index) => {
              return <option>{item}</option>;
            })}
          </select>
          <h5>Priorität</h5>
          <span>
            <input type='number' defaultValue={5} min={0} max={10}></input>
          </span>
        </>
      )}
      {chosenDep === 2 && <div>Zeitzeug</div>}
      <span></span>
      <FaCheck
        className='output-icon save'
        onClick={() => {
          console.log(tempAction);
          if (chosenDep === 0) setOutputActions([...outputActions, tempAction]);
          if (chosenDep === 1)
            setOutputActions([...outputActions, inputAction]);
        }}
      />
    </div>
  );
};

export default AddOutputAction;
