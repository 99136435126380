import React, { useState, useEffect } from "react";
import "./RemoteWidget.css";
import InputWidget from "./InputWidget";
import OutputWidget from "./OutputWidget";
import TempRemoteWidget from "./TempRemoteWidget";
import StatusRemoteWidget from "./StatusRemoteWidget";
import StatusAnlageWidget from "./StatusAnlageWidget";
import { GoTools } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { getNickName } from "../01_Helpers/nameHelpers";

const checkAnlagenStatusForErrors = (status) => {
  if (status == null) return 1;
  let statusHex = Buffer.from(status, "hex");
  if (statusHex[1] === 0) return 1;
  return 0;
};

const checkAnlageNoConnect = (lastReport) => {
  if (lastReport == null) return 0;
  if (lastReport - Date.now() / 1000 - 300) return 0;
  return 1;
};

const RemoteWidget = (props) => {
  const { remoteID, hasError } = props;
  const [lastRecord, setLastRecord] = useState("");
  const [nickName, setNickName] = useState("");
  const [inputState, setInputState] = useState(0);
  const [outputState, setOutputState] = useState(0);
  const [temperature, setTemperature] = useState({
    tempInternal: null,
    tempExternal: null,
  });
  const [remoteOk, setRemoteOk] = useState(1);
  const [saaState, setSaaState] = useState(1);
  const [saaConnect, setSaaConnect] = useState(1);
  let [anlagenID, setAnlagenID] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [remoteNoData, setRemoteNoData] = useState(false);
  const navigate = useNavigate();
  const anlagenAliases = JSON.parse(localStorage.getItem("navbar")).anlagen;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let widgetData = () => {
      fetch(`${props.href}?timeLimit=latest`, {
        signal: signal,
        method: "GET",
        headers: {
          Authorization: props.token,
          "accept-encoding": "gzip",
        },
      })
        .then(async (res) => {
          res = await res.json();
          if (res.statusRemote?.unixtimestamp == null) {
            setIsLoaded(true);
            return setRemoteNoData(true);
          }
          setRemoteNoData(false);
          setInputState(await res.statusRemote.inputs);
          setOutputState(await res.statusRemote.outputs);
          setTemperature(await res.tempRemote);
          let lastRecRaw = new Date(parseInt(res.statusRemote.unixtimestamp));
          let lastReCString = lastRecRaw.toLocaleString();
          setLastRecord(lastReCString);

          if (res.statusRemote.unixtimestamp < Date.now() - 2 * 60000) {
            setRemoteOk(0);
            setIsLoaded(1);
            if (res.scope?.anlagen[0] != null)
              setAnlagenID(res.scope.anlagen[0].anlagenID);
            return "noConnection";
          }

          setRemoteOk(1);
          if (res.scope.anlagen[0]?.href == null) {
            setIsLoaded(true);
            return;
          }
          fetch(`${res.scope.anlagen[0].href}?table=statusVigilis`, {
            signal: signal,
            method: "GET",
            headers: {
              Authorization: props.token,
            },
          }).then(async (resAnlage) => {
            resAnlage = await resAnlage.json();
            setSaaState(
              checkAnlagenStatusForErrors(resAnlage.statusVigilis.status)
            );
            setSaaConnect(
              checkAnlageNoConnect(resAnlage.statusVigilis.unixtimestamp)
            );
            setAnlagenID(resAnlage.statusVigilis.anlagenID);
            setIsLoaded(true);
          });
        })
        .catch((err) => err);
    };
    widgetData();
    setNickName(getNickName(props.remoteID));
    let constantlyUpdate = setInterval(widgetData, 60000);

    return () => {
      controller.abort();
      clearInterval(constantlyUpdate);
    };
  }, []);

  useEffect(() => {
    if (hasError == null) return;
    hasError(remoteID, saaState);
  }, [saaState]);

  if (remoteNoData)
    return (
      <div
        className={
          props.display === "card" ? "remoteWidget card" : "remoteWidget frame"
        }
        bottompadding='0'
      >
        <div className='remoteWidgetHeader'>
          <h3>RemoteID: {props.remoteID}</h3>
        </div>
        <div
          className='remoteWidgetBody'
          style={{ gridTemplateColumns: "1fr" }}
        >
          <h3>Keine Daten vorhanden!</h3>
        </div>
      </div>
    );
  if (!isLoaded)
    return (
      <div
        className={
          props.display === "card" ? "remoteWidget card" : "remoteWidget frame"
        }
        bottompadding='0'
      >
        <div className='remoteWidgetHeader'>
          <h3>RemoteID: {props.remoteID}</h3>
        </div>
        <div
          className='remoteWidgetBody gridItemsCenter'
          style={{ gridTemplateColumns: "1fr" }}
        >
          <div>
            <ScaleLoader color='rgba(255, 166, 0, 0.6)'></ScaleLoader>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={
        props.display === "card"
          ? "remoteWidget card bg-black gap05"
          : "remoteWidget frame gap05"
      }
      bottompadding='0'
    >
      <div className='remoteWidgetHeader padding05'>
        <h3 className='marginToBoAuto'>
          {nickName !== "" ? `${nickName}` : `${props.remoteID}`}
        </h3>
        <h3 className='marginToBoAuto red'>
          {!remoteOk && "keine Verbindung"}
        </h3>
        <div className='gridItemsCenter'>
          <GoTools
            className='pointer resizeOnHover13'
            size={20}
            onClick={() =>
              navigate(`/settings/modules/${props.remoteID}/settings`)
            }
          />
        </div>
      </div>
      <div
        className={
          remoteOk
            ? "remoteWidgetBody gridItemsCenter gap10"
            : "remoteWidgetBody gridItemsCenter opacity04 gap10"
        }
      >
        <Link
          className='widgetAsLink  resizeOnHover11 '
          to={`/settings/modules/${props.remoteID}/inputs`}
        >
          <InputWidget inputState={inputState} remoteOk={remoteOk} />
        </Link>
        <Link
          className='widgetAsLink  resizeOnHover11 '
          to={`/settings/modules/${props.remoteID}/outputs`}
        >
          <OutputWidget outputState={outputState} remoteOk={remoteOk} />
        </Link>
        <Link
          className='widgetAsLink  resizeOnHover11 '
          to={`/settings/modules/${props.remoteID}/temperatur`}
        >
          <TempRemoteWidget
            temperatur={temperature}
            remoteOk={remoteOk}
          ></TempRemoteWidget>
        </Link>
        <Link className='widgetAsLink resizeOnHover11 ' to={"#"}>
          <StatusRemoteWidget status={remoteOk} />
        </Link>
        {anlagenID != null && anlagenID != 0 && (
          <Link
            className='widgetAsLink resizeOnHover11 '
            to={`/settings/anlagen/${anlagenID}`}
          >
            <StatusAnlageWidget
              status={saaState}
              remoteOk={remoteOk}
              saaConnect={saaConnect}
            />
          </Link>
        )}
      </div>
      <div className='remoteWidgetBottom'>
        <hr orientation='horizontal' />
        <div className='remoteWidgetFooter gridItemsCenter gap05 padding05'>
          <p
            className={
              remoteOk ? "orange-800 textAlignCenter" : "red textAlignCenter"
            }
          >
            Stand - {lastRecord} Uhr
          </p>
          {anlagenID == null ? (
            <p className='gray-800 textAlignCenter'>
              Keine Sprachalamierungsanlage
            </p>
          ) : (
            <p className='gray-800 textAlignCenter'>
              Anlagen ID : {anlagenID} -{" "}
              {anlagenAliases.reduce((prev, item) => {
                return item.anlagenID === anlagenID
                  ? `Objekt : ${item.name}`
                  : prev;
              }, "keine Daten")}{" "}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RemoteWidget;
