import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import "./htmlTags.css";
import "./Composition.css";
import "./Utility.css";
import "./App.css";
import "./TopBar.css";
import "./index.css";
import { company } from "./config";

let icLink = document.createElement("link");
let shortcutIcLink = document.createElement("link");
let manLink = document.createElement("link");
icLink.type = "image/x-icon";
icLink.rel = "icon";
shortcutIcLink.type = "image/x-icon";
shortcutIcLink.rel = "shortcut icon";
manLink.rel = "manifest";
manLink.href = "./manifestThum.json";
let title = document.createElement("title");
title.text = "Thum Remote Portal";
let head = document.getElementsByTagName("head");
if (company === "priosafe") {
  icLink.href = "./priosafe.png";
  shortcutIcLink.href = "./priosafe.png";
  title.text = "Priosafe Remote Portal";
  head[0].appendChild(icLink);
  head[0].appendChild(shortcutIcLink);
  manLink.href = "./manifestPrio.json";
}
head[0].appendChild(title);
head[0].appendChild(manLink);

let rootElement = document.querySelector(":root");
let innerHeight = window.innerHeight + "px";
rootElement.style.setProperty("--real-height", innerHeight);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
