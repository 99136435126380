import { baseurl } from "./endpoints";

export async function getUserDB() {
  return await fetch(`${baseurl}/users`, {
    method: "GET",
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")),
      "Accept-Encoding": "gzip",
    },
  });
}

export async function getRemoteTable(table) {
  return await fetch(`${baseurl}/modules?table=${table}`, {
    method: "GET",
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")),
      "Accept-Encoding": "gzip",
    },
  });
}

export async function getRemoteAndNickNames() {
  return await fetch(`${baseurl}/modules?table=RemoteNickName`, {
    method: "GET",
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")),
      "Accept-Encoding": "gzip",
    },
  });
}

export async function adminPatchAllDB(patchBody) {
  return await fetch(`${baseurl}/admin/alldb`, {
    method: "PATCH",
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")),
      "Accept-Encoding": "gzip",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(patchBody),
  })
    .then(async (res) => {
      try {
        return await res.text();
      } catch (err) {
        return console.error(err);
      }
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
}
