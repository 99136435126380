import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "./InputSettings.css";
import { BsFillGearFill } from "react-icons/bs";
import { FaTimes, FaCheck } from "react-icons/fa";

const patchInputConfig = (
  href,
  token,
  remoteInputIndex,
  remoteInputName,
  remoteInputMessage,
  notification
) => {
  let body = {
    remoteInputConfig: [
      {
        remoteInputIndex,
        remoteInputName,
        remoteInputMessage,
        notification,
      },
    ],
  };
  return fetch(href, {
    method: "PATCH",
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
};

const InputWidget = (props) => {
  const scope = JSON.parse(localStorage.getItem("scope"));
  const token = JSON.parse(localStorage.getItem("token"));
  const { remoteID } = useParams();
  const href = scope.remote.find((item) => item.remoteID === remoteID).href;

  const [editMode, setEditMode] = useState(false);
  const [prevName, setPrevName] = useState("");
  const [prevMessage, setPrevMessage] = useState("");
  let inputNameRef = useRef(null);
  let inputMessageRef = useRef(null);
  return (
    <div className='input-widget card gap05 padding10'>
      <div className='remoteInputSettingsIndex orange-600 gridItemsCenter'>
        {props.input.remoteInputIndex + 1}
      </div>
      <div className='remoteInputSettingsNotify gridItemsCenter'></div>
      <div className='remoteInputSettingsMainHead'>
        {editMode ? (
          <h3
            contentEditable='true'
            className='editable'
            autoFocus
            ref={inputNameRef}
          >
            {props.input.remoteInputName}
          </h3>
        ) : (
          <h3 ref={inputNameRef}>{props.input.remoteInputName}</h3>
        )}
      </div>
      <div className='remoteInputSettingsMainFoot gray-600 padding05'>
        {editMode ? (
          <p contentEditable='true' className='editable' ref={inputMessageRef}>
            {props.input.remoteInputMessage}
          </p>
        ) : (
          <p ref={inputMessageRef}>{props.input.remoteInputMessage}</p>
        )}
      </div>
      <hr orientation='vertical' className='gridItemsCenter' />
      {editMode ? (
        <div className='remoteInputSettingsEdit gridItemsCenter'>
          <FaTimes
            className='output-icon abort'
            onClick={() => {
              inputMessageRef.current.textContent = prevMessage;
              inputNameRef.current.textContent = prevName;

              setEditMode(!editMode);
            }}
          />
          <FaCheck
            className='output-icon save'
            onClick={async () => {
              await patchInputConfig(
                href,
                token,
                props.input.remoteInputIndex,
                inputNameRef.current.textContent,
                inputMessageRef.current.textContent
              );
              setEditMode(!editMode);
            }}
          />
        </div>
      ) : (
        <div className='remoteInputSettingsEdit'>
          <BsFillGearFill
            onClick={() => {
              setPrevMessage(inputMessageRef.current.textContent);
              setPrevName(inputNameRef.current.textContent);
              setEditMode(!editMode);
            }}
            className='output-icon settings'
          />
        </div>
      )}
    </div>
  );
};

export default InputWidget;

/*
<div className="input-widget-body">

                    <div className="input-index inputDiv">
                        <span className="inputIndexHeader inHeader">Nr</span>
                        <span className="inBody">{props.input.remoteInputIndex + 1}</span>
                    </div>
                    <div className="input-name inputDiv">
                        <span className="inputNameHeader inHeader">Name</span>
                        <span className="inBody">{props.input.remoteInputName}</span>
                    </div>

                    <div className="input-message inputDiv">
                    <span className="inputMessageHeader inHeader">Nachricht</span>
                    <span className="inBody">{props.input.remoteInputMessage}</span>    
                    </div>
                    <div className="input-notification inputDiv">
                        <span className="inputNotificationHeader inHeader">Benachrichtigung</span>
                        <span className="inBody">{props.input.notification}</span>
                    </div>

            </div>
*/

/*
{notification ? (
  <BiMailSend
    onClick={editMode ? () => setNotification(!notification) : () => {}}
    className={editMode ? "output-icon orange-600" : "orange-600"}
  />
) : (
  <BiMailSend
    onClick={editMode ? () => setNotification(!notification) : () => {}}
    className={editMode ? "output-icon gray-600" : "gray-600"}
  />
)}
*/
