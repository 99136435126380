import React from "react";
import { decodeImpedanzen } from "../01_Helpers/anlagenImpedanzenHelper";
import "./AnlagenDash.css";

const Impedanzen = (props) => {
  let impedanzen = props.impedanzen;
  let impedanzenDecoded = decodeImpedanzen(impedanzen);
  let bereichsBezeichnungen = JSON.parse(
    localStorage.getItem("bereichsBezeichnungen")
  );
  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Impedanzen {props.linienIndex}</h3>
      </div>
      <div className='anlagenWidgetBody'>
        <div className='ImpedanzFrame gap05'>
          <div className='ImpedanzHeader bold  gridItemsCenter '>
            <span>Linie</span>
            <span>@1kHz</span>
            <span>Leistung</span>
            <span>Referenz</span>
            <span>Aktuell</span>
            <span className='lsLinienBezeichnung'>Name</span>
          </div>
          {impedanzenDecoded.map((item, index, array) => {
            return (
              <div
                key={index}
                className='ImpedanzEntry bgColorOrangeOnHover gridItemsCenter gray-800 borderRadius10 lineHeight15'
              >
                <span>{`${item.messlinie}`}</span>
                <span>{item.z1kHz > 4000 ? "offen" : `${item.z1kHz}Ω`}</span>
                <span>
                  {item.z1kHz > 4000
                    ? "0W"
                    : `${Math.round(100000 / item.z1kHz) / 10}W`}
                </span>
                <span>{item.zRef > 4000 ? "offen" : `${item.zRef}Ω`}</span>
                <span>{item.zAkt > 4000 ? "offen" : `${item.zAkt}Ω`}</span>
                <span className='lsLinienBezeichnung'>
                  {bereichsBezeichnungen[index]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Impedanzen;
