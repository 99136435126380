import React from "react";
import { Outlet } from "react-router-dom";

const NotificationsMainFrame = (props) => {
  return (
    <div className='frame padding10 displayBlock'>
      <h3>Benachrichtigungen</h3>
      <Outlet />
    </div>
  );
};

export default NotificationsMainFrame;
