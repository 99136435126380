import React, { useRef } from "react";
import { FaSearch } from "react-icons/fa";
import "./SearchBar.css";

const Searchbar = (props) => {
  const { arrayToFilter, setOutput, keysToFilterBy } = props;

  return (
    <span className='searchContainer'>
      <input
        placeholder='Suche...'
        type='text'
        className='searchBarInput'
        onChange={(e) => {
          let outputArray = [];
          let keysToFilterByWithoutTo = keysToFilterBy.filter(
            (item) => item !== "to"
          );
          keysToFilterByWithoutTo.forEach((key) => {
            let filteredByKey = arrayToFilter.filter((item) =>
              item[key].toLowerCase().includes(e.target.value.toLowerCase())
            );
            outputArray = [...outputArray, ...filteredByKey];
          });
          if (e.target.value === "") return setOutput(arrayToFilter);
          setOutput(outputArray);
        }}
      ></input>
      <FaSearch className='searchIcon orange-800'></FaSearch>
    </span>
  );
};

export default Searchbar;
