import React from "react";
import { useParams } from "react-router-dom";
import {
  decodeAnlagenStatus,
  decodeAnlagenStoerung,
} from "../01_Helpers/anlagenStatusHelper";
import "./AnlagenDash.css";
import {
  BsExclamationTriangle,
  BsCheckCircle,
  BsInfoCircle,
  BsFillVolumeUpFill,
  BsFillCircleFill,
  BsCircle,
} from "react-icons/bs";

function getIcon(entry, isTrue) {
  return isTrue ? (
    <BsFillCircleFill color={"orange"} />
  ) : (
    <BsCircle color={"grey"} />
  );
}

const AnlagenStoerungsWidget = (props) => {
  let status = props.status;
  let anlagenStatusDecoded = decodeAnlagenStoerung(status);
  let anlagenStatusFiltered = anlagenStatusDecoded.filter(
    (item) => Object.values(item)[0] === true
  );
  let anlagenIcons = anlagenStatusDecoded.map((item) =>
    getIcon(Object.keys(item)[0], Object.values(item)[0])
  );

  let { anlagenID } = useParams();
  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Störungen</h3>
      </div>
      <div className='anlagenWidgetBody'>
        {anlagenStatusDecoded.map((item, index, array) => {
          let [key, value] = Object.entries(item)[0];
          return (
            <div className='StatusEntry marginTop05' key={index}>
              <span className='statusKey gray-800 bold'>{key}</span>
              <span className='statusValue textAlignCenter'>
                {anlagenIcons[index]}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnlagenStoerungsWidget;
