import React, { useState, useEffect } from "react";
import { ScaleLoader } from "react-spinners";
import { FaTimes } from "react-icons/fa";
const UserRemoteList = (props) => {
  const {
    userRemoteList,
    editMode,
    setUserRemoteList,
    setEditRemoteScope,
    editRemoteScope,
  } = props;
  let [displayRemoteList, setDisplayRemoteList] = useState(userRemoteList);
  useEffect(() => {
    setDisplayRemoteList(userRemoteList);
  }, [userRemoteList]);
  if (!userRemoteList)
    return (
      <div className='marginTop10'>
        <ScaleLoader color='rgba(255, 166, 0, 0.6)'></ScaleLoader>
      </div>
    );
  return (
    <div className='marginTop10'>
      <h5 className='textAlignStart'>Remotes</h5>
      <ul className='textAlignStart marginTop05 gray-600 paddingLeft10'>
        {displayRemoteList.map((remote, index) => {
          return editMode ? (
            <li key={index} className='flexRow gap05'>
              <span>
                <FaTimes
                  className='red resizeWithOpacity marginToBoAuto pointer'
                  onClick={(e) => {
                    setDisplayRemoteList(
                      displayRemoteList.filter((element) => element != remote)
                    );
                    setEditRemoteScope(
                      editRemoteScope.filter((item) => item !== remote.remoteID)
                    );
                  }}
                />
              </span>
              <span className='marginToBoAuto'>{remote.name}</span>
            </li>
          ) : (
            <li key={index}>{remote.name}</li>
          );
        })}
      </ul>
    </div>
  );
};

export default UserRemoteList;
