import React, { useEffect, useState } from "react";
import { FaTimes, FaTrashAlt, FaUndoAlt } from "react-icons/fa";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { ScaleLoader } from "react-spinners";

const Adressbuch = (props) => {
  let location = localStorage.getItem("location");
  let [prevAdressBook, setPrevAdressBook] = useState([]);
  let [adressBook, setAdressBook] = useState([]);
  let [emailListString, setEmailListString] = useState("");
  let [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetch(`${location}`, {
      method: "GET",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "accept-encoding": "gzip",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Not Okay! HTTP sagt: " + res.status);
        }
        return res.json();
      })
      .then((res) => {
        if (res?.adressBook == null) setAdressBook([]);
        setAdressBook(JSON.parse(res.adressBook));
        setIsLoaded(true);
        return res;
      })
      .catch((e) => console.error);
  }, [location]);

  return (
    <div className='card padding10 maxWidth600px marginTop10'>
      <h3>Adressbuch</h3>
      <div className='flexRow flexSpaceBetween gap05 marginTop10'>
        <div className='card width50pro padding10'>
          {isLoaded === false ? (
            <div className='gridItemsCenter orange-600'>
              <ScaleLoader color='rgba(255, 165, 0, 0.6)' />
            </div>
          ) : adressBook.length === 0 ? (
            <h5>Ihr Adressbuch ist leer</h5>
          ) : (
            <div className='displayBlock'>
              <h5 className='maxHeightMinContent'>Adressbuch</h5>
              <ul className='textAlignStart'>
                {adressBook.map((item, index, array) => {
                  return (
                    <li key={index} className='gridCol02ch1fr marginTop03'>
                      <span className='red gridItemsCenter'>
                        <FaTimes
                          className='placeSelfCenter resizeOnHover13 pointer'
                          onClick={() => {
                            setPrevAdressBook([...adressBook]);
                            array.splice(index, 1);
                            return setAdressBook([...array]);
                          }}
                        />
                      </span>
                      <span className='gray-800 bold paddingLeft05'>
                        {item}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <form className='flexGrow marginLeft10'>
          <label>Adressen hinzufügen</label>
          <textarea
            className='displayBlock'
            value={emailListString}
            placeholder='Geben Sie eine oder mehrere Email Adressen ein, um diese Ihrem Adressbuch hinzuzufügen. Mehrere Adressen müssen durch Kommata getrennt werden.'
            onChange={(e) => {
              setEmailListString(e.target.value);
            }}
          />
          <div className='gridCol3x1fr gridItemsCenter'>
            <FaTrashAlt
              className='red fontSize15 resizeWithOpacity pointer'
              onClick={() => {
                setPrevAdressBook([...adressBook]);
                setAdressBook([]);
              }}
            />

            {adressBook.length !== prevAdressBook.length ? (
              <FaUndoAlt
                className='fontSize15 resizeWithOpacity pointer'
                onClick={() => setAdressBook([...prevAdressBook])}
              />
            ) : (
              <span></span>
            )}
            <HiOutlinePlusCircle
              className='green fontSize20 resizeWithOpacity pointer'
              onClick={(e) => {
                e.preventDefault();
                let splitMailString = emailListString.split(",");
                let adressesBeforeCheck = splitMailString.map((item) =>
                  item.trim()
                );
                let adressesThatFailedCheck = adressesBeforeCheck.reduce(
                  (prev, item) => {
                    if (item.includes(" ")) return [...prev, item];
                    if (item.includes("\n")) return [...prev, item];
                    let adressSplittedByAtCharacter = item.split("@");
                    if (adressSplittedByAtCharacter.length !== 2)
                      return [...prev, item];
                    let domainSeperatedByDot =
                      adressSplittedByAtCharacter[1].split(".");
                    if (domainSeperatedByDot.length < 2) return [...prev, item];
                    if (adressSplittedByAtCharacter[0].length === 0)
                      return [...prev, item];
                    if (domainSeperatedByDot[0].length === 0)
                      return [...prev, item];
                    if (domainSeperatedByDot[1].length === 0)
                      return [...prev, item];
                    return prev;
                  },
                  []
                );
                if (adressesThatFailedCheck.length !== 0) {
                  let errorMessage =
                    "Fehler beim einlesen der E-Mail Adressen, bitte überprüfen Sie folgende Einträge: \n\n";
                  adressesThatFailedCheck.forEach(
                    (item, index) =>
                      (errorMessage += `\t ${index + 1}.\t ${item} \n`)
                  );
                  return alert(errorMessage);
                }
                let filterForDuplicates = adressesBeforeCheck.filter((item) => {
                  return !adressBook.includes(item);
                });
                setEmailListString("");
                setPrevAdressBook([...adressBook]);
                return setAdressBook([...adressBook, ...filterForDuplicates]);
              }}
            />
          </div>
        </form>
      </div>
      <span className='marginTop10'>
        <hr orientation='horizontal' />{" "}
      </span>
      <button
        onClick={() => {
          let strigifiedAdressBook = JSON.stringify(adressBook);
          let requestBody = {
            UserAdressBook: {
              adressBook: strigifiedAdressBook,
            },
          };
          fetch(location, {
            method: "PATCH",
            headers: {
              Authorization: localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });
        }}
      >
        Speichern
      </button>
    </div>
  );
};

export default Adressbuch;
