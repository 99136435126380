let iconv = require("iconv-lite");

exports.decodeBezeichnungen = (data) => {
  let dataHex = new Buffer.from(data, "hex");
  let makroBezeichnungen = [];
  let bereichsBezeichnungen = [];
  let eingangsBezeichnungen = [];
  for (let i = 0; i < 256 * 16; i += 16) {
    makroBezeichnungen.push(
      iconv.decode(
        dataHex.slice(i, i + 15).filter((item) => item !== 0x00),
        "latin-1"
      )
    );
  }
  for (let i = 256 * 16; i < 256 * 16 * 2; i += 16) {
    bereichsBezeichnungen.push(
      iconv.decode(
        dataHex.slice(i, i + 15).filter((item) => item !== 0x00),
        "latin-1"
      )
    );
  }
  for (let i = 256 * 16 * 2; i < 256 * 16 * 3; i += 16) {
    eingangsBezeichnungen.push(
      iconv.decode(
        dataHex.slice(i, i + 15).filter((item) => item !== 0x00),
        "latin-1"
      )
    );
  }
  localStorage.setItem(
    "makroBezeichnungen",
    JSON.stringify(makroBezeichnungen)
  );
  localStorage.setItem(
    "bereichsBezeichnungen",
    JSON.stringify(bereichsBezeichnungen)
  );
  localStorage.setItem(
    "eingangsBezeichnungen",
    JSON.stringify(eingangsBezeichnungen)
  );
};
