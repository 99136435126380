const avAbschaltungen = [
  "Audio",
  "BMZ E1/E2",
  "Störausgang",
  "Steuereingänge",
  "LS Linien A",
  "LS Linien B",
  "Steuerrelais",
  "Linienrelais",
];

exports.decodeAbschaltungen = (abschaltungen) => {
  let abschaltungenHex = new Buffer.from(abschaltungen, "hex");
  let byte1Abschaltungen = [];
  for (let i = 1, j = 0; i <= 0b10000000; i <<= 1, j++) {
    if (abschaltungenHex[0] & i) byte1Abschaltungen.push(avAbschaltungen[j]);
  }
  for (let i = 1, j = 0; i <= 0b10000000; i <<= 1, j++) {
    if (abschaltungenHex[1] & i)
      byte1Abschaltungen.push(`ID: ${33 + j} - Power-Unit`);
  }
  if (abschaltungenHex[2] !== 0)
    byte1Abschaltungen.push(`Endverstärker Nr.:${abschaltungenHex[2]}`);
  return byte1Abschaltungen;
};
