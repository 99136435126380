import React from "react";
import { decodePowerUnit } from "../01_Helpers/anlagenPowerUnitHelper";

const AnlagenPowerUnitWidget = (props) => {
  let powerUnit = props.powerunit;
  let powerUnitDecod = decodePowerUnit(powerUnit);

  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Power-Unit</h3>
      </div>
      <div className='anlagenWidgetBody'>
        {Object.keys(powerUnitDecod).map((item, index, array) => {
          return (
            <div className='PowerUnitEntry marginTop05' key={index}>
              <span className='puKey gray-800 bold'>{item}</span>
              <span className='puVal'>
                {Object.values(powerUnitDecod)[index]}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnlagenPowerUnitWidget;
