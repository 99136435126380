import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RightNav from "./RightNav";
import SideNav from "./SideNav";

const setIDHeader = (navbar, whereToLook, whatToFind, identity) => {
  return navbar[whereToLook].reduce(
    (prev, item) => (item[whatToFind] === identity ? item.name : prev),
    null
  );
};
const getRoute = (navbar, whereToLook, whatToFind, identity) => {
  return navbar[whereToLook].reduce(
    (prev, item) => (item[whatToFind] === identity ? item.to : prev),
    null
  );
};
const getActivIndex = (navbar, whereToLook, whatToFind, identity) => {
  return navbar[whereToLook].reduce(
    (prev, item, index) => (item[whatToFind] === identity ? index : prev),
    null
  );
};

const getActiveMainIndex = (navbar, pathname) => {
  return navbar.main.reduce(
    (prev, item, index) => (pathname.includes(item.to) ? index : prev),
    null
  );
};

const Nav = (props) => {
  const { loggedIn, setLoggedIn, navbar, setNavbar } = props;
  const [submenu, setSubMenu] = useState(null);
  const [route, setRoute] = useState(null);
  const [rightMenuHeader, setRightMenuHeader] = useState(null);
  const [subMenuHeader, setSubMenuHeader] = useState(null);
  const [mainMenuActiveIndex, setMainMenuActiveIndex] = useState(null);
  const [subMenuActiveIndex, setSubMenuActiveIndex] = useState(null);
  const [refresh, setRefresh] = useState(false);
  let location = useLocation();

  useEffect(() => {
    console.log(navbar);
    let nav = JSON.parse(localStorage.getItem("navbar"));
    if (navbar == null) setNavbar(nav);
    if (navbar == null) return;
    let urlParts = location.pathname.split("/");
    urlParts = urlParts.filter((item) => item !== "");
    setMainMenuActiveIndex(getActiveMainIndex(navbar, location.pathname));
    switch (urlParts[0]) {
      case "dashboard":
        setSubMenu(null);
        setRightMenuHeader(null);
        setRoute(null);
        setSubMenuHeader(null);
        break;
      case "settings":
        if (urlParts[1] == null) return;
        switch (urlParts[1]) {
          case "anlagen":
            setRightMenuHeader("Anlagen");
            setSubMenu("anlagen");
            setRoute(null);
            if (urlParts[2] != null) {
              setSubMenuHeader(
                setIDHeader(navbar, "anlagen", "anlagenID", urlParts[2])
              );
              setRoute(getRoute(navbar, "anlagen", "anlagenID", urlParts[2]));
              if (urlParts[3] != null) {
                setSubMenuActiveIndex(
                  getActivIndex(navbar.submenu, "anlagen", "to", urlParts[3])
                );
                return;
              }
              setSubMenuActiveIndex(0);
              return;
            }
            break;
          case "modules":
            setSubMenu("remotes");
            setRightMenuHeader("Remotes");
            setRoute(null);
            if (urlParts[2] != null) {
              setSubMenuHeader(
                setIDHeader(navbar, "remotes", "remoteID", urlParts[2])
              );
              setRoute(getRoute(navbar, "remotes", "remoteID", urlParts[2]));
              if (urlParts[3] != null) {
                setSubMenuActiveIndex(
                  getActivIndex(navbar.submenu, "remotes", "to", urlParts[3])
                );
                return;
              }
              setSubMenuActiveIndex(0);
              return;
            }
            break;
          case "notifications":
            setSubMenuHeader("Nachrichten");
            setSubMenu("notifications");
            setRoute("/settings/notifications");
            if (urlParts[2] != null) {
              setSubMenuActiveIndex(
                getActivIndex(
                  navbar.submenu,
                  "notifications",
                  "to",
                  urlParts[2]
                )
              );
              return;
            }
            setSubMenuActiveIndex(null);
            break;
          case "user":
            setSubMenuHeader("Benutzer");
            setSubMenu("userSettings");
            setRoute("/settings/user");
            if (urlParts[2] != null) {
              setSubMenuActiveIndex(
                getActivIndex(navbar.submenu, "userSettings", "to", urlParts[2])
              );
              return;
            }
            setSubMenuActiveIndex(null);
            break;
          default:
            return;
        }

        break;
      case "admin":
        setSubMenuHeader("Administration");
        setSubMenu("admin");
        setRoute("/admin");
        if (urlParts[1] != null) {
          setSubMenuActiveIndex(
            getActivIndex(navbar.submenu, "admin", "to", urlParts[1])
          );
          return;
        }
        setSubMenuActiveIndex(null);
        break;
      default:
        return;
    }
    setRefresh(!refresh);
  }, [location, navbar]);

  useEffect(() => {
    if (loggedIn && navbar == null) {
      let nav = localStorage.getItem("navbar");
      if (nav == null) {
        localStorage.clear();
        return setLoggedIn(false);
      }
      setNavbar(JSON.parse(nav));
    }

    if (loggedIn === false) setNavbar(null);
  }, []);

  if (loggedIn === false || navbar == null) return <></>;

  return (
    <>
      <SideNav
        refresh={refresh}
        navbar={navbar}
        submenu={submenu}
        setSubMenu={setSubMenu}
        route={route}
        setRoute={setRoute}
        setRightMenuHeader={setRightMenuHeader}
        subMenuHeader={subMenuHeader}
        subMenuActiveIndex={subMenuActiveIndex}
        mainMenuActiveIndex={mainMenuActiveIndex}
      ></SideNav>
      <RightNav
        refresh={refresh}
        submenu={submenu}
        navbar={navbar}
        setRoute={setRoute}
        rightMenuHeader={rightMenuHeader}
        setSubMenuHeader={setSubMenuHeader}
      ></RightNav>
    </>
  );
};

export default Nav;
