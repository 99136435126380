import React from "react";

const DatenschutzPriosafe = (props) => {
  return (
    <div className='frame displayBlock marginTop10 padding10'>
      <h3>Datenschutz</h3>
      <div className='card textAlignStart maxWidth700px marginTop10 padding10 gray-800 displayBlock'>
        <h3>DATENSCHUTZBESTIMMUNGEN</h3>
        <p className='marginTop10'>
          Wir messen dem Datenschutz große Bedeutung bei. Die Erhebung und
          Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung
          der geltenden datenschutzrechtlichen Vorschriften, insbesondere der
          EU-Datenschutzgrundverordnung (DSGVO).
        </p>
        <h5 className='marginTop10'>
          1. Verantwortliche Stelle für die Datenverarbeitung
        </h5>
        <p className='marginTop10'>
          Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung
          Ihrer personenbezogenen Daten im Sinne der DSGVO ist ​<br />
        </p>
        <p className='marginTop05'>
          Priosafe GmbH ​<br />
          Marie-Curie-Straße 13 ​<br />
          40822 Mettmann ​<br />
          Telefon: +49 2104 80029-0 ​<br />
          E-Mail: info@priosafe.de ​<br /> ​
        </p>
        <p className='marginTop05'>
          Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch
          uns nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für
          einzelne Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch
          an oben genannte verantwortliche Stelle richten. ​ Sie können diese
          Datenschutzerklärung jederzeit speichern und ausdrucken.
        </p>

        <h5 className='marginTop10'>
          2. Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten
        </h5>
        <p className='marginTop05'>
          Im Folgenden informieren wir über die Erhebung personenbezogener Daten
          bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten,
          die auf Sie persönlich beziehbar sind, z. B. Name, Adresse,
          E-Mail-Adresse sowie das Nutzerverhalten.
        </p>
        <h5 className='marginTop05'>2.1. Beim Besuch der Website </h5>
        <p className='marginTop05'>
          Bei der bloß informatorischen Nutzung der Website www.priosafe.de,
          also wenn Sie sich nicht regisitrieren oder uns anderweitig
          Informationen übermitteln, erheben wir nur die personenbezogenen
          Daten, die Ihr Browser an unseren Server übermittelt. Um Ihnen die
          Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten,
          sind folgende Daten technisch erforderlich und werden deshalb von uns
          erhoben:
        </p>
        <ul className='marginTop05'>
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>
            verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
            der Name Ihres Access-Providers
          </li>
        </ul>
        <h5 className='marginTop05'>
          2.2. Bei Nutzung unseres Kontaktformulars
        </h5>
        <p className='marginTop05'>
          Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
          Kontaktformular werden die von Ihnen mitgeteilte Daten (Ihre
          E-Mail-Adresse, Ihr Name ggfs. Telefonnummer und der Firmenname) von
          uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem
          Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung
          nicht mehr erforderlich ist, oder schränken die Verarbeitung ein,
          falls gesetzliche Aufbewahrungspflichten bestehen.
        </p>
        <h5 className='marginTop05'>2.3. Bei der Nutzung unseres Webshops</h5>
        <p className='marginTop05'>
          Wenn Sie in unserem Webshop bestellen möchten, ist es für den
          Vertragsabschluss erforderlich, dass Sie Ihre persönlichen Daten
          angeben, die wir für die Abwicklung Ihrer Bestellung benötigen. Für
          die Abwicklung der Verträge notwendige Pflichtangaben sind gesondert
          markiert, weitere Angaben sind freiwillig. Die von Ihnen angegebenen
          Daten verarbeiten wir zur Abwicklung Ihrer Bestellung. Dazu können wir
          Ihre Zahlungsdaten an unsere Hausbank weitergeben. Rechtsgrundlage ist
          hierfür Art. 6 Abs. ! S. 1 lit. b DSGVO.
        </p>
        <p className='marginTop05'>
          Sie können außerdem freiwillig ein Kundenkonto anlegen, durch das wir
          Ihre Daten für spätere weitere Einkäufe speichern können. Bei Anlegung
          eines Accounts unter „Mein Konto“werden die von Ihnen angegebene Daten
          widerruflich gespeichert. Alle weiteren Daten, inklusive Ihres
          Nutzerkontos, können Sie im Kundenbereich stets löschen. Wir können
          die von Ihnen angegebenen Daten zudem verarbeiten, um Sie über weitere
          interessante Produkte aus unserem Portfolio zu informieren oder Ihnen
          E-Mails mit technischen Infomationen zukommen zu lassen.
        </p>
        <p className='marginTop05'>
          Wir sind aufgrund handels- und steuerrechtlicher Vorgaben
          verpflichtet, Ihre Adress-, Zehlungs- und Bestelldaten für die Dauer
          von zehn Jahren zu speichern. Allerdings nehmen wir nach zwei Jahren
          eine Einschränkung der Verarbeitung vor, d. h. Ihre Daten werden nur
          zur Einhaltung der gesetzlichen Verpflichtung eingesetzt.
        </p>
        <p className='marginTop05'>
          Zur Verhinderung unberechtigter Zugriffe Dritter auf Ihre persönliche
          Daten, insbesondere Finanzdaten, wird der Bestellvorgang per
          TLS-Technik verschlüsselt.
        </p>

        <h5 className='marginTop10'>
          3. Einsatz von Google-Analytics mit Anonymisierungsfunktion
        </h5>
        <p className='marginTop05'>
          Wir setzen auf unserer Seite Google-Analytics, einen Webanalysedienst
          der Firma Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
          94043 USA, nachfolgend „Google“ ein. Google-Analytics verwendet sog.
          „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und
          hierdurch eine Analyse der Benutzung der Website durch Sie
          ermöglichen.
        </p>
        <p className='marginTop05'>
          Die durch diese Cookies erzeugten Informationen, beispielsweise Zeit,
          Ort und Häufigkeit Ihres Webseiten-Besuchs einschließlich Ihrer
          IP-Adresse, werden an Google in den USA übertragen und dort
          gespeichert. Wir verwenden auf unserer Website Google-Analytics mit
          dem Zusatz „_ gat._anonymizeIp“. Ihre IP-Adresse wird in diesem Fall
          von Google schon innerhalb von Mitgliedstaaten der Europäischen Union
          oder in anderen Vertragsstaaten des Abkommens über den Europäischen
          Wirtschaftsraum gekürzt und dadurch anonymisiert. Google wird diese
          Informationen benutzen, um Ihre Nutzung unserer Seite auszuwerten, um
          Reports über die Websiteaktivitäten für uns zusammenzustellen und um
          weitere mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen zu erbringen. Auch wird Google diese Informationen
          gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
          vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
          verarbeiten. Google wird, nach eigenen Angaben, in keinem Fall Ihre
          IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie
          können die Installation der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen unserer Website vollumfänglich nutzen können.
        </p>
        <p className='marginTop05'>
          Des Weiteren bietet Google für die gängigsten Browser ein
          Deaktivierungs- Add-on an, welches Ihnen mehr Kontrolle darüber gibt,
          welche Daten von Google zu der von Ihnen aufgerufenen Websites erfasst
          werden. Das Add-on teilt dem JavaScript (ga.js) von Google Analytics
          mit, dass keine Informationen zum Website-Besuch an Google Analytics
          übermittelt werden sollen. Das Deaktivierungs-Add-on für Browser von
          Google Analytics verhindert aber nicht, dass Informationen an uns oder
          an andere von uns gegebenenfalls eingesetzte Webanalyse-Services
          übermittelt werden. Weitere Informationen zur Installation des Browser
          Add-on erhalten Sie über nachfolgenden Link:
          https://tools.google.com/dlpage/gaoptout?hl=de ​
        </p>

        <h5 className='marginTop10'>4. Einsatz von Cookies</h5>
        <p className='marginTop05'>
          Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um
          kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem
          Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn
          Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen
          Schaden an, enthalten keine Viren, Trojaner oder sonstige
          Schadsoftware. In dem Cookie werden Informationen abgelegt, die sich
          jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät
          ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar
          Kenntnis von Ihrer Identität erhalten. Der Einsatz von Cookies dient
          einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu
          gestalten. So setzen wir sogenannte Session-Cookies ein, um zu
          erkennen, dass Sie einzelne Seiten unserer Website bereits besucht
          haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.
          ​
        </p>
        <p className='marginTop05'>
          Darüber hinaus setzen wir ebenfalls zur Optimierung der
          Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
          festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen
          Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird
          automatisch erkannt, dass Sie bereits bei uns waren und welche
          Eingaben und Einstellungen sie getätigt haben, um diese nicht noch
          einmal eingeben zu müssen. Zum anderen setzten wir Cookies ein, um die
          Nutzung unserer Website statistisch zu erfassen und zum Zwecke der
          Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5).
          Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer
          Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese
          Cookies werden nach einer jeweils definierten Zeit automatisch
          gelöscht.
        </p>
        <p className='marginTop05'>
          Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke
          zur Wahrung unserer berechtigten Interessen sowie der Dritter nach
          Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
        </p>
        <p className='marginTop05'>
          Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren
          Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer
          gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer
          Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann
          jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website
          nutzen können.
        </p>

        <h5 className='marginTop10'>5. Betroffenenrecht</h5>
        <p className='marginTop05'>
          Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
          betreffenden persosnenbzogenen Daten:
        </p>
        <ul className='marginTop05'>
          <li>
            Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu
            verlangen
          </li>
          <li>
            unverzüglich die Berichtigung unrichtiger oder Vervollständigung
            Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen
          </li>
          <li>
            die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu
            verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf
            freie Meinungsäußerung und Information, zur Erfüllung einer
            rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses
            oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen erforderlich ist
          </li>
          <li>
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen
          </li>
          <li>
            Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in
            einem strukturierten, gängigen und maschinenlesebaren Format zu
            erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
            verlangen
          </li>
          <li>
            Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu
            widerrufen
          </li>
        </ul>
        <p className='marginTop05'>
          Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde
          über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu
          beschweren.
        </p>
        <h5 className='marginTop10'>6. Datensicherheit</h5>
        <p className='marginTop05'>
          Wir verwenden innerhalb des Website-Besuchs das verbreitete
          SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
          höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
          wird. In der Regel handelt es sich dabei um eine 256 Bit
          Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung
          unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
          zurück. Ob eine einzelne Seite unseres Internetauftrittes
          verschlüsselt übertragen wird, erkennen Sie an der geschlossenen
          Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der
          unteren Statusleiste Ihres Browsers.
        </p>
        <p className='marginTop05'>
          Wir bedienen uns im Übrigen geeigneter technischer und
          organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige
          oder vorsätzliche Manipulationen, teilweisen oder vollständigen
          Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
          schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der
          technologischen Entwicklung fortlaufend verbessert.
        </p>
        <h5 className='marginTop10'>7. Widerspruchsrecht</h5>
        <p className='marginTop05'>
          Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
          Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
          haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
          Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
          Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder
          sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall
          haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
          besonderen Situation von uns umgesetzt wird.
        </p>
        <p className='marginTop05'>
          Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen, genügt eine E-Mail an info@priosafe.de ​
        </p>
        <h5 className='marginTop10'>
          8. Aktualität und Änderung dieser Datenschutzerklärung
        </h5>
        <p className='marginTop05'>
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai
          2018. Durch die Weiterentwicklung unserer Website und Angebote darüber
          oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
          Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu
          ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf
          der Website unter https://www.priosafe.de/datenschutz von Ihnen
          abgerufen und ausgedruckt werden.
        </p>
      </div>
    </div>
  );
};

export default DatenschutzPriosafe;
