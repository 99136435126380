export function decodeAnlagenStatus(statusString) {
  let dataHex = new Buffer.from(statusString, "hex");
  return [
    { Betrieb: dataHex[0] & (1 << 0) ? true : false },
    { Betriebsbereit: dataHex[0] & (1 << 1) ? true : false },
    { Sprachalamierung: dataHex[0] & (1 << 2) ? true : false },
    { Störungen: dataHex[0] & (1 << 3) ? true : false },
    { Abschaltung: dataHex[0] & (1 << 4) ? true : false },
  ];
}

export function decodeAnlagenStatusAsObject(statusString) {
  let dataHex = new Buffer.from(statusString, "hex");
  return {
    Betrieb: dataHex[0] & (1 << 0) ? true : false,
    Betriebsbereit: dataHex[0] & (1 << 1) ? true : false,
    Sprachalamierung: dataHex[0] & (1 << 2) ? true : false,
    Störungen: dataHex[0] & (1 << 3) ? true : false,
    Abschaltung: dataHex[0] & (1 << 4) ? true : false,
  };
}

export function decodeAnlagenStoerung(statusString) {
  let dataHex = new Buffer.from(statusString, "hex");
  return [
    { System: dataHex[1] & (1 << 0) ? true : false },
    { Energieversorgung: dataHex[1] & (1 << 1) ? true : false },
    { Brandmeldezentrale: dataHex[1] & (1 << 2) ? true : false },
    { Notfallmikrofon: dataHex[1] & (1 << 3) ? true : false },
    { Leistungsverstärker: dataHex[1] & (1 << 4) ? true : false },
    { Lautsprecherkreise: dataHex[1] & (1 << 5) ? true : false },
    { Bestandteile: dataHex[1] & (1 << 6) ? true : false },
    { Erdschluss: dataHex[1] & (1 << 7) ? true : false },
  ];
}

export function decodeInfoByte(statusString) {
  let dataHex = new Buffer.from(statusString, "hex");
  if (dataHex[4] === 0) return [];
  let infoByteContent = [
    "Schaltzeiten gesperrt",
    "Amp-Unit: Eingang Aktiv",
    "E1 aktiv",
    "E2 aktiv",
    "Störung: MP3",
  ];
  let sonstiges = [];
  for (let i = 1, j = 0; i < 100000; i <<= 1, j++) {
    if (dataHex[4] & i) sonstiges.push(infoByteContent[j]);
  }
  return sonstiges;
}
