import React from "react";
import { decodeSchaltzeiten } from "../01_Helpers/anlagenSchaltzeitenHelper";

const AnlagenSchaltzeitenWidget = (props) => {
  let schaltzeiten = props.schaltzeiten;
  let schaltzeitenDecod = decodeSchaltzeiten(schaltzeiten);
  let makroBezeichnungen = JSON.parse(
    localStorage.getItem("makroBezeichnungen")
  );
  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Schaltzeiten</h3>
      </div>
      <div className='anlagenWidgetBody'>
        {schaltzeitenDecod.map((item, index, array) => {
          return (
            <div
              className='marginTop05 schaltzeitenEntry bgColorOrangeOnHover borderRadius10 lineHeight15'
              key={index}
            >
              <span className='gray-800 bold'>{item.uhrzeit} Uhr</span>
              <span>{item.tage}</span>
              <span>{makroBezeichnungen[item.makronum]}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnlagenSchaltzeitenWidget;
