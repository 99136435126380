exports.decodeKomponenten = (data) => {
  let dataHex = new Buffer.from(data, "hex");
  let komponentenArray = [];
  let typ = "";
  for (let i = 0; i < dataHex.length; i++) {
    if (dataHex[i] !== 0x51) continue;
    if (i === 0) typ = "Installations PC";
    else if (i < 33) typ = "Verstärker/Relais Box";
    else if (i < 39) typ = "Notstrom";
    else if (i < 44) typ = "Panel PC";
    else if (i < 48) typ = "FW Sprechstelle";
    else typ = "MTP";

    komponentenArray.push({
      komponentenid: i,
      komponente: typ,
    });
  }

  return komponentenArray;
};
