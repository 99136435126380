import React from "react";
import "./RemoteWidget.css";
import { BsCircleFill, BsCircle } from "react-icons/bs";

function OutputWidget(props) {
  return (
    <div className='SubWidget card gridItemsCenter padding00 delay2'>
      <h4>Ausgänge</h4>
      {props.remoteOk ? (
        <div className='OutputsBody content'>
          <div className='Outputs'>
            {props.outputState & (1 << 0) ? (
              <BsCircleFill className='OutputCircle orange-800' key='out0' />
            ) : (
              <BsCircle className='OutputCircle' key='out0' />
            )}
            {props.outputState & (1 << 1) ? (
              <BsCircleFill
                className='OutputCircle orange-800'
                key='OutputCircleout1'
              />
            ) : (
              <BsCircle className='OutputCircle' key='OutputCircleout1' />
            )}
          </div>
        </div>
      ) : (
        <div className='notAvailable'>n.A.</div>
      )}
    </div>
  );
}

export default OutputWidget;
