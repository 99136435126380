import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./SideNav.css";

/*
const setUnactiveByClassName = (className) => {
  let selectedCollection = document.getElementsByClassName(className);
  for (let element of selectedCollection) {
    element.classList.remove("active");
  }
};
*/

function SideNav(props) {
  let {
    submenu,
    route,
    subMenuHeader,
    subMenuActiveIndex,
  } = props;
  let location = useLocation();

  if (props.navbar?.main == null) return <></>;

  return (
    <nav className='sidenav' id='sideNav'>
      <div className='LinkContainer'>
        <div className='topSection'>
          <h5>Navigation</h5>
          <hr orientation='horizontal'></hr>
          {props.navbar.main.map((item, index) => {
            return (
              <Link
                className={
                  location.pathname.startsWith(item.to)
                    ? "sidenavLink general mainLink active"
                    : "sidenavLink general mainLink"
                }
                to={item.to}
                key={index}
                onClick={(e) => {
                  if (window.innerWidth <= 900)
                    document.getElementById("sideNav").style.display = "none";
                }}
              >
                {item.name}
              </Link>
            );
          })}
        </div>
        {route != null && (
          <div className='adaptiveLinks'>
            <h5>{subMenuHeader}</h5>
            <hr orientation='horizontal'></hr>
            {props.navbar.submenu[submenu] != null &&
              props.navbar.submenu[submenu].map((item, index) => {
                return (
                  <Link
                    className={
                      index === subMenuActiveIndex
                        ? "sidenavLink special subLink active"
                        : "sidenavLink special subLink "
                    }
                    to={route + "/" + item.to}
                    key={index}
                    onClick={(e) => {
                      if (window.innerWidth <= 900)
                        document.getElementById("sideNav").style.display =
                          "none";
                    }}
                  >
                    {item.name}
                  </Link>
                );
              })}
          </div>
        )}
      </div>
    </nav>
  );
}

export default SideNav;

/*
<a href="#" className="sidenavLink">Link1</a>
<a href="#" className="sidenavLink">Link2</a>
<a href="#" className="sidenavLink">Link3</a>
<a href="#" className="sidenavLink">Link4</a>
*/

/*
    {
        name: 'Eingänge',
        to: 'eingaenge',
        route: '/settings/anlagen'            
    },
        {
        name: 'Lautsprecherlinien',
        to: 'lslinien',
        route: '/settings/anlagen'            
    },
    {
        name: 'Endverstärker',
        to: 'endverstaerker',
        route: '/settings/anlagen'            
    }
*/

/* 
<div className="adaptiveLinks">
                <h5>Remotes</h5>
                <hr/>
                <div>
                    {
                        navlinksRem.map((item, index) => <Link 
                            to={item.to} 
                            className="sidenavLink general"
                            key={index}
                            onClick={(event) => {
                                let special = generateSpecialContent('remote', item.name);
                                setSpecialContent(special);
                                setSpecialMenu(true);
                                setUnactiveByClassName('sidenavLink')    
                                event.target.classList.add('active')
                            }}
                            >{item.name}</Link>)               
                    }
                </div>
            </div>
*/
