import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsFillCircleFill, BsCircle } from "react-icons/bs";

const RemoteOverviewWidget = (props) => {
  const { remoteContext } = props;
  const [remoteStatus, setRemoteStatus] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [remoteCon, setRemoteCon] = useState(true);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    let getData = () =>
      fetch(`${remoteContext.updateLink}?table=statusRemote`, {
        signal: signal,
        method: "GET",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          let inputsFromStatus = [];
          let outputsFromStatus = [];
          if (res.statusRemote.unixtimestamp < Date.now() - 2 * 60000)
            return setRemoteCon(false);
          setRemoteCon(true);
          for (let i = 1; i <= 0b10000000; i <<= 1) {
            inputsFromStatus.push(i & res.statusRemote.inputs);
          }
          for (let i = 1; i <= 0b10; i <<= 1) {
            outputsFromStatus.push(i & res.statusRemote.outputs);
          }
          setInputs(inputsFromStatus);
          setOutputs(outputsFromStatus);
        })
        .catch((e) => console.error(e));
    getData();
    let interval = setInterval(getData, 60000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, []);

  return (
    <Link
      to={remoteContext.portalLink}
      className='remoteOverviewWidget card gap10 padding05 bgColorOrangeOnHover'
    >
      <span className='orange-800 textAlignStart'>
        {remoteContext.remoteName}
      </span>
      {remoteCon ? (
        <>
          <span className='remoteInputs'>
            <p>Eingänge:</p>
            {inputs.map((item, index) =>
              item ? (
                <BsFillCircleFill color='orange' key={index} />
              ) : (
                <BsCircle key={index} />
              )
            )}
          </span>
          <span className='remoteOutputs'>
            <p>Ausgänge</p>
            {outputs.map((item, index) =>
              item ? (
                <BsFillCircleFill color='orange' key={index} />
              ) : (
                <BsCircle key={index} />
              )
            )}
          </span>
        </>
      ) : (
        <span className="span2 flexFlexEnd">
          <h5 className="red">Keine aktuellen Remote Daten</h5>
        </span>
      )}
    </Link>
  );
};

export default RemoteOverviewWidget;
