import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables, TimeScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import "chartjs-adapter-moment";
import moment from "moment";
import TempSettings from "./TempSettings";
import { company } from "../config";

Chart.register(TimeScale);

const RemoteTemperatureSettingsFrame = (props) => {
  let { remoteID } = useParams();
  let scope = JSON.parse(localStorage.getItem("scope"));
  let token = JSON.parse(localStorage.getItem("token"));
  let thisRessource = scope.remote.find((item) => item.remoteID === remoteID);
  const tempChart = useRef(null);
  let [hasData, setHasData] = useState(false);

  let data = {
    labels: [1, 2, 3, 4, 5, 6, 7],
    datasets: [
      {
        label: "Intern",
        data: [18, 20, null, 24, 22, 18, 17],
        fill: false,
        backgroundColor: company === "thum" ? "orange" : "darkgrey",
        borderColor: company === "thum" ? "orange" : "darkgrey",
      },
      {
        label: "Extern",
        data: [22, 24, 25, 28, 30, 28, 25],
        fill: false,
        backgroundColor: company === "thum" ? "grey" : "lightblue",
        borderColor: company === "thum" ? "grey" : "lightblue",
      },
    ],
  };
  const [chartData, setChartData] = useState(data);
  let options = {
    plugins: {
      title: {
        display: true,
        text: "Temperaturverlauf",
      },
      decimation: {
        enabled: true,
        algorithm: "lttb",
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(201, 201, 201, 0.204)",
        },
        type: "time",
        time: {
          displayFormats: {
            hour: "DD.MM - HH:MM",
          },
          unit: "hour",
        },
        min: moment.unix(Date.now() / 1000 - 7 * 24 * 60 * 60),
      },
      y: {
        grid: {
          display: true,
          color: "rgba(201, 201, 201, 0.204)",
        },
        beginAtZero: true,
      },
    },
    elements: {
      point: {
        radius: 1,
      },
    },

    plugins: {
      annotation: {},
    },
  };

  useEffect(() => {
    fetch(`${thisRessource.href}?table=tempRemote`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      res.json().then((Tdata) => {
        let tempInternalData = Tdata.tempRemote.map((item, index) => {
          return {
            x: moment.unix(parseInt(item.unixtimestamp) / 1000),
            y: item.tempInternal,
          };
        });

        let tempExternalData = Tdata.tempRemote.map((item, index) => {
          return {
            y: item.tempExternal,
            x: moment.unix(parseInt(item.unixtimestamp) / 1000),
          };
        });
        let labels = Tdata.tempRemote.map(
          (item, index) => new Date(item.unixtimestamp)
        );
        //data.labels= labels.filter((item, index) => index % (1) === 0);
        data.datasets[0].data = tempInternalData.filter(
          (item, index) => index % 1 === 0
        );
        data.datasets[1].data = tempExternalData.filter(
          (item, index) => index % 1 === 0
        );
        setChartData(data);
        setHasData(true);
      });
    });
  }, []);

  return (
    <>
      <div className='card padding10 marginTop10 maxWidth700px'>
        <h3>Temperaturverlauf</h3>
        {hasData && (
          <Line
            data={chartData}
            options={options}
            ref={tempChart}
            redraw={true}
            style={{ maxWidth: "800px", maxHeight: "500px" }}
          ></Line>
        )}
      </div>
    </>
  );
};

export default RemoteTemperatureSettingsFrame;
