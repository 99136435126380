import React from "react";
import "./RemoteWidget.css";
import { BsCircleFill, BsCircle } from "react-icons/bs";

function InputWidget(props) {
  return (
    <div className='SubWidget card gridItemsCenter delay1 padding00'>
      <h4>Eingänge</h4>
      {props.remoteOk ? (
        <div className='InputsBody content'>
          <div className='Inputs gap05'>
            {props.inputState & (1 << 0) ? (
              <BsCircleFill className='InputCircle orange-800' key='in0' />
            ) : (
              <BsCircle className='InputCircle' key='in0' />
            )}
            {props.inputState & (1 << 1) ? (
              <BsCircleFill className='InputCircle orange-800' key='in1' />
            ) : (
              <BsCircle className='InputCircle' key='in1' />
            )}
            {props.inputState & (1 << 2) ? (
              <BsCircleFill className='InputCircle orange-800' key='in2' />
            ) : (
              <BsCircle className='InputCircle' key='in2' />
            )}
            {props.inputState & (1 << 3) ? (
              <BsCircleFill className='InputCircle orange-800' key='in3' />
            ) : (
              <BsCircle className='InputCircle' key='in3' />
            )}

            {props.inputState & (1 << 4) ? (
              <BsCircleFill className='InputCircle orange-800' key='in4' />
            ) : (
              <BsCircle className='InputCircle' key='in4' />
            )}
            {props.inputState & (1 << 5) ? (
              <BsCircleFill className='InputCircle orange-800' key='in5' />
            ) : (
              <BsCircle className='InputCircle' key='in5' />
            )}
            {props.inputState & (1 << 6) ? (
              <BsCircleFill className='InputCircle orange-800' key='in6' />
            ) : (
              <BsCircle className='InputCircle' key='in6' />
            )}
            {props.inputState & (1 << 7) ? (
              <BsCircleFill className='InputCircle orange-800' key='in7' />
            ) : (
              <BsCircle className='InputCircle' key='in7' />
            )}
          </div>
        </div>
      ) : (
        <div className='notAvailable'>n.A.</div>
      )}
    </div>
  );
}

export default InputWidget;
